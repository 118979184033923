/* eslint-disable import/prefer-default-export */
import { FunctionComponent } from 'react';
import { CONTROLLERS } from 'pages/ExposedEndpoints/Form/constants';
import ActivationCodesListing from 'pages/ExposedEndpoints/Preview/ActivationCodes';

export const LISTINGS: {
  [key: string]: FunctionComponent<PreviewListingProps>;
} = {
  [CONTROLLERS.ACTIVATION_CODES]: ActivationCodesListing,
};
