import { Icon, IconProps } from '@chakra-ui/react';

function Filter(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_674_5991"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_674_5991)">
        <path
          d="M11.25 20.75V15.25H12.75V17.25H20.75V18.75H12.75V20.75H11.25ZM3.25 18.75V17.25H8.74995V18.75H3.25ZM7.25 14.75V12.75H3.25V11.25H7.25V9.25003H8.74995V14.75H7.25ZM11.25 12.75V11.25H20.75V12.75H11.25ZM15.25 8.74998V3.25003H16.75V5.25003H20.75V6.74998H16.75V8.74998H15.25ZM3.25 6.74998V5.25003H12.75V6.74998H3.25Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Filter;
