const Switch = {
  baseStyle: {
    container: {
      display: 'flex',
      alignItems: 'center',
      h: 'auto',
    },
    thumb: {
      width: '18px',
      height: '18px',
      boxShadow:
        '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: 'complementary.white',

      _checked: {
        bg: 'complementary.white',
      },
    },
    track: {
      '--switch-thumb-x': '24px',
      width: '42px',
      padding: '4px',
      height: '18px',
      alignItems: 'center',
      bg: '#ddd',
      _checked: {
        bg: 'complementary.primary',
      },
      _focusVisible: {
        outline: 'none',
        boxShadow: 'unset',
      },
    },
  },
};

export default Switch;
