import { useIsMutating } from 'react-query';
import MainLayout from 'components/Layout/MainLayout';
import Table from 'pages/ExposedEndpoints/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetExposedEndpoints } from 'api/exposedEndpoints';
import Add from 'components/Icons/Add';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import { useFormDrawer } from 'context/FormDrawerContext';
import ExposedEndpointCreate from 'pages/ExposedEndpoints/Form/Create';

function ExposedEndpointsListing() {
  const { setFormDrawer } = useFormDrawer();
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const isMutating = useIsMutating({
    mutationKey: ['exposedEndpointsMutation'],
  });

  const {
    isFetching,
    data: { data: exposedEndpoints, totals } = { data: [], totals: 0 },
  } = useGetExposedEndpoints(queryString);
  const showLoading = isMutating || isFetching;

  return (
    <MainLayout
      title="Udostępnione"
      headerButtons={[
        {
          label: 'Dodaj',
          leftIcon: <Add />,
          onClick: () =>
            setFormDrawer({
              title: 'Udostępnij',
              content: <ExposedEndpointCreate />,
            }),
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={exposedEndpoints} />}
      <Footer totals={totals} />
    </MainLayout>
  );
}

export default ExposedEndpointsListing;
