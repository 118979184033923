import { Icon, IconProps } from '@chakra-ui/react';

function Copy(props: IconProps) {
  return (
    <Icon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_512_2574"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_512_2574)">
        <path
          d="M3.8333 14.2C3.49997 14.2 3.21663 14.0834 2.9833 13.85C2.74997 13.6167 2.6333 13.3334 2.6333 13V4.53337H3.6333V13C3.6333 13.0556 3.65263 13.1027 3.6913 13.1414C3.73041 13.1805 3.77775 13.2 3.8333 13.2H10.3V14.2H3.8333ZM6.16663 11.8667C5.8333 11.8667 5.54997 11.75 5.31663 11.5167C5.0833 11.2834 4.96663 11 4.96663 10.6667V3.08337C4.96663 2.73892 5.0833 2.45003 5.31663 2.2167C5.54997 1.98337 5.8333 1.8667 6.16663 1.8667H11.75C12.0944 1.8667 12.3833 1.98337 12.6166 2.2167C12.85 2.45003 12.9666 2.73892 12.9666 3.08337V10.6667C12.9666 11 12.85 11.2834 12.6166 11.5167C12.3833 11.75 12.0944 11.8667 11.75 11.8667H6.16663ZM6.16663 10.8667H11.75C11.8055 10.8667 11.8555 10.8471 11.9 10.808C11.9444 10.7694 11.9666 10.7223 11.9666 10.6667V3.08337C11.9666 3.02781 11.9444 2.97781 11.9 2.93337C11.8555 2.88892 11.8055 2.8667 11.75 2.8667H6.16663C6.11108 2.8667 6.06397 2.88892 6.0253 2.93337C5.98619 2.97781 5.96663 3.02781 5.96663 3.08337V10.6667C5.96663 10.7223 5.98619 10.7694 6.0253 10.808C6.06397 10.8471 6.11108 10.8667 6.16663 10.8667Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Copy;
