import styled from '@emotion/styled';

const Td = styled.td`
  :nth-of-type(1) {
    width: 320px;
  }
  :nth-of-type(2) {
    width: 320px;
  }
  :nth-of-type(3) {
    width: 320px;
  }
  :nth-of-type(4) {
    text-align: right;
  }
`;

export default Td;
