import { HStack } from '@chakra-ui/react';
import ColorPicker from 'components/Form/Color';

function BlankCouponForm() {
  return (
    <>
      <HStack spacing={2}>
        <ColorPicker label="Kolor tekstu" name="textColor" />
        <ColorPicker label="Kolor tła tekstu" name="textBgColor" />
      </HStack>
      <HStack spacing={2}>
        <ColorPicker label="Kolor dividera" name="borderColor" />
        <ColorPicker label="Kolor przycisku" name="linkColor" />
      </HStack>
    </>
  );
}

export default BlankCouponForm;
