import {
  AccordionButton as ChakraAccordionButton,
  AccordionIcon,
  Box,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import Delete from 'components/Icons/Delete';

interface AccordionItemProps {
  title: string;
  remove?: () => void;
}

function AccordionButton({ title, remove }: AccordionItemProps) {
  return (
    <ChakraAccordionButton as={Box}>
      <HStack w="100%" spacing={2}>
        <Text variant="h4" fontWeight={600} w="100%" align="left">
          {title}
        </Text>
        <IconButton
          variant="unstyled"
          icon={<AccordionIcon fontSize="24px" />}
          aria-label="toggle"
          minWidth="24px"
          height="24px"
        />
        {remove && (
          <IconButton
            variant="unstyled"
            icon={<Delete />}
            onClick={remove}
            aria-label="remove"
            minWidth="24px"
            height="24px"
          />
        )}
      </HStack>
    </ChakraAccordionButton>
  );
}

AccordionButton.defaultProps = {
  remove: null,
};

export default AccordionButton;
