/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { API, DEFAULT_STALE_TIME } from 'api';

const fetchCouponsUsage = async (queryString: string) => {
  const { data } = await API.get(
    `${process.env.REACT_APP_ANALYTICS_API_URL}/coupons-usage.json?${queryString}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
      },
    },
  );
  return data;
};

const useGetCouponsUsage = (queryString: string, enabled: boolean) =>
  useQuery<{ count: number }>(
    ['couponsUsage', queryString],
    () => fetchCouponsUsage(queryString),
    {
      enabled,
      staleTime: DEFAULT_STALE_TIME,
    },
  );

export { useGetCouponsUsage };
