import {
  FormControl,
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

type SwitchProps = ChakraSwitchProps & {
  name: string;
  label?: string | React.ReactNode;
};

function Switch({
  label,
  name,
  isRequired,
  defaultChecked,
  ...rest
}: SwitchProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      width="auto"
      display="flex"
      alignItems="center"
      gap={2}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultChecked}
        render={({ field: { onChange, value } }) => (
          <>
            <ChakraSwitch
              id={name}
              isChecked={value}
              onChange={onChange}
              {...rest}
            />
            <FormLabel htmlFor={name} mb={0} w="max-content" fontSize="16px">
              {label}
            </FormLabel>
          </>
        )}
      />
      <FormErrorMessage pt={3}>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

Switch.defaultProps = {
  label: '',
};

export default Switch;
