import { FunctionComponent, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack, Text } from '@chakra-ui/react';
import { PreviewProps } from 'types/common';
import BlankCouponPreview from 'pages/CouponPools/Form/components/Preview/CouponPreviews/BlankCouponPreview';
import CouponWithImagePreview from 'pages/CouponPools/Form/components/Preview/CouponPreviews/CouponWithImagePreview';
import B2BCouponPreview from 'pages/CouponPools/Form/components/Preview/CouponPreviews/B2BCouponPreview';
import BlankCouponModalPreview from 'pages/CouponPools/Form/components/Preview/CouponModalPreviews/BlankCouponModalPreview';
import CouponWithImageModalPreview from 'pages/CouponPools/Form/components/Preview/CouponModalPreviews/CouponWithImageModalPreview';
import B2BCouponModalPreview from 'pages/CouponPools/Form/components/Preview/CouponModalPreviews/B2BCouponModalPreview';
import PreviewPlaceholder from 'pages/CouponPools/Form/components/Preview/PreviewPlaceholder';
import { TEMPLATE_TYPE } from 'pages/CouponPools/Form/constants';

export const PREVIEWS: {
  [key: string]: {
    CouponPreview: FunctionComponent<PreviewProps>;
    CouponModalPreview: FunctionComponent<PreviewProps>;
  };
} = {
  [TEMPLATE_TYPE.BLANK]: {
    CouponPreview: BlankCouponPreview,
    CouponModalPreview: BlankCouponModalPreview,
  },
  [TEMPLATE_TYPE.WITH_IMAGE]: {
    CouponPreview: CouponWithImagePreview,
    CouponModalPreview: CouponWithImageModalPreview,
  },
  [TEMPLATE_TYPE.B2B]: {
    CouponPreview: B2BCouponPreview,
    CouponModalPreview: B2BCouponModalPreview,
  },
};

function CouponPreview() {
  const { control } = useFormContext();
  const formValues = useWatch({ control });

  const PreviewComponent = useMemo(
    () => PREVIEWS[formValues?.templateType],
    [formValues?.templateType],
  );

  return (
    <Stack spacing={4} w="100%" maxW="376px">
      <Stack
        padding="24px 32px"
        backgroundColor="complementary.lightBlue"
        borderRadius="8px"
        spacing={4}
      >
        <Text fontWeight={600}>Podgląd kuponu</Text>
        {PreviewComponent ? (
          <PreviewComponent.CouponPreview formValues={formValues} />
        ) : (
          <PreviewPlaceholder text="Wybierz typ kuponu, aby wygenerować podgląd" />
        )}
      </Stack>
      <Stack
        padding="24px 32px"
        width="100%"
        maxW="376px"
        backgroundColor="complementary.lightBlue"
        borderRadius="8px"
        spacing={4}
      >
        <Text fontWeight={600}>Podgląd modala kuponu</Text>
        {PreviewComponent ? (
          <PreviewComponent.CouponModalPreview formValues={formValues} />
        ) : (
          <PreviewPlaceholder text="Wybierz typ kuponu, aby wygenerować podgląd modala" />
        )}
      </Stack>
    </Stack>
  );
}

export default CouponPreview;
