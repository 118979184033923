import { Box, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {
  CONTROLLERS,
  CONTROLLER_OPTIONS,
} from 'pages/ExposedEndpoints/Form/constants';
import InternalLabelSelect from 'components/InternalLabelSelect';

function FormBody({ isLoading }: FormBodyProps) {
  const { control } = useFormContext();
  const controller = useWatch({
    control,
    name: 'controller',
  });

  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Input name="name" label="Nazwa" placeholder="Wpisz nazwę" />
        <Input name="token" label="Token" placeholder="Wpisz token" />
        <Select name="controller" label="Typ" options={CONTROLLER_OPTIONS} />
        {controller === CONTROLLERS.ACTIVATION_CODES && (
          <InternalLabelSelect name="queryParams.internalLabel" />
        )}
      </Stack>
    </Box>
  );
}

export default FormBody;
