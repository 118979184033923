import { pick } from 'lodash';
import { transformToOption } from 'pages/SubscriptionCoupons/Form/constants';

const parseDefaultValues = (coupon: SubscriptionCoupon) => {
  const result: Record<string, any> = pick(coupon, [
    'code',
    'value',
    'subApiUser',
    'validFrom',
    'subscriptionLevel',
  ]);

  result.value = parseInt(coupon.value.toString(), 10);
  result.validFrom = coupon?.validFrom ? new Date(coupon.validFrom) : null;
  result.user = transformToOption(coupon?.subApiUser);

  delete result.subApiUser;

  return result;
};

export default parseDefaultValues;
