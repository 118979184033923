import { Box, Flex } from '@chakra-ui/react';
import Sidebar from 'components/Sidebar';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <Flex w="100%">
      <Sidebar />
      <Box
        backgroundColor="complementary.superLightPurple"
        minH="100vh"
        w="100%"
        padding="52px 32px"
        overflow="auto"
      >
        {children}
      </Box>
    </Flex>
  );
}

export default Layout;
