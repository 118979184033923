import { Box, Button, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { PreviewProps } from 'types/common';

function BlankCouponPreview({ formValues }: PreviewProps) {
  return (
    <HStack
      w="100%"
      h="132px"
      bgColor={formValues?.textBgColor ?? 'complementary.white'}
      color={formValues?.textColor ?? 'complementary.black'}
      padding="8px 8px 8px 16px"
      spacing={4}
    >
      <Box display="flex" width="32px" padding={2}>
        <Box height="auto">
          <Text
            fontWeight={700}
            whiteSpace="nowrap"
            transformOrigin="top left"
            transform="rotate(-90deg) translate(-100%)"
            marginTop="-50%"
          >
            -10 PLN
          </Text>
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          borderColor: formValues?.borderColor ?? 'complementary.badgeColor',
        }}
      />
      <Stack spacing={0}>
        <Text fontSize="12px" lineHeight="20px">
          Opis kuponu
        </Text>
        <Text fontSize="12px" lineHeight="20px">
          Kod wygasa: Wprowadź datę
        </Text>
        <Button
          variant="link"
          padding={0}
          fontSize="10px"
          lineHeight="20px"
          fontWeight={700}
          width="fit-content"
          color={formValues?.linkColor ?? 'complementary.primary'}
        >
          Użyj w sklepie
        </Button>
      </Stack>
    </HStack>
  );
}

export default BlankCouponPreview;
