import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { FOOTER_HEIGHT } from 'components/Footer';

type ListingActionButton = ButtonProps & {
  label: string;
  to?: string;
  tooltip?: string;
};

interface MainLayoutProps {
  children: React.ReactNode;
  title: string;
  headerButtons: ListingActionButton[];
  filterBody?: React.ReactNode;
}

function MainLayout({
  title,
  headerButtons,
  children,
  filterBody,
}: MainLayoutProps) {
  return (
    <Box w="100%" mb={FOOTER_HEIGHT}>
      <HStack justify="space-between" mb="52px">
        <Text variant="h1">{title}</Text>
        <HStack spacing="8px">
          {map(headerButtons, ({ label, tooltip, ...buttonProps }, idx) => (
            <Tooltip key={`button-${label}-${idx}`} label={tooltip ?? false}>
              <Button {...buttonProps}>{label}</Button>
            </Tooltip>
          ))}
        </HStack>
      </HStack>
      {filterBody && filterBody}
      {children}
    </Box>
  );
}

MainLayout.defaultProps = {
  filterBody: null,
};

export default MainLayout;
