import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormControlProps,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import Input from 'components/Form/Color/input';

type ColorPickerProps = FormControlProps & {
  label?: string;
  name: string;
  showError?: boolean;
  isRequired?: boolean;
  hidden?: boolean;
};

function ColorPicker({
  label,
  name,
  showError,
  isRequired,
  hidden,
  ...rest
}: ColorPickerProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      hidden={hidden}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
      {...rest}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Input onChange={onChange} value={value} />
        )}
      />
      {showError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

ColorPicker.defaultProps = {
  hidden: false,
  isRequired: false,
  showError: false,
  label: '',
};

export default ColorPicker;
