import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import { ERROR_MESSAGES } from 'utils/form';

enum CONTROLLERS {
  ACTIVATION_CODES = 'ActivationCodesController',
}

const CONTROLLERS_DICT: {
  [K: string]: string;
} = {
  [CONTROLLERS.ACTIVATION_CODES]: 'Kody aktywacyjne',
};

const CONTROLLER_OPTIONS = Object.entries(CONTROLLERS_DICT).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

const DEFAULT_VALUES = {
  name: '',
  token: uuidv4(),
  controller: CONTROLLERS.ACTIVATION_CODES,
  queryParams: {},
};

const BODY_SCHEMA = yup.object({
  name: yup.string().nullable().required(ERROR_MESSAGES.required),
  token: yup.string().nullable().required(ERROR_MESSAGES.required),
  controller: yup.string().nullable().required(ERROR_MESSAGES.required),
  queryParams: yup.object({
    internalLabel: yup
      .string()
      .nullable()
      .when('controller', {
        is: (controller: string) => controller !== CONTROLLERS.ACTIVATION_CODES,
        then: (schema) => schema.required(ERROR_MESSAGES.required),
      }),
  }),
});

type FormData = yup.InferType<typeof BODY_SCHEMA>;

export {
  CONTROLLERS,
  CONTROLLERS_DICT,
  CONTROLLER_OPTIONS,
  DEFAULT_VALUES,
  BODY_SCHEMA,
};
export type { FormData };
