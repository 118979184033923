import { datePrettier } from 'utils/date';

const BASIC_FIELDS = [
  {
    label: 'Kod',
    value: 'code',
  },
  {
    label: 'Opis',
    value: 'description',
  },
  {
    label: 'Wartość',
    value: 'value',
  },
  { label: 'Pula', value: 'couponPool.label' },
  { label: 'Ważny od', value: 'validFrom', formatter: datePrettier },
  { label: 'Wykorzystany', value: 'usedAt' },
  { label: 'Wartość zamówienia', value: 'externalTransactionsTotal' },
  { label: 'Numer zamówienia', value: 'eShopOrderNumber' },
  { label: 'Numer dokumentu sprzedaży', value: 'salesDocumentNumber' },
  { label: 'Poziom subskrypcji', value: 'subscriptionLevel' },
];

const TRANSACTION_EVENT_FIELDS = [
  {
    label: 'Typ',
    value: 'type',
  },
  {
    label: 'Wartość',
    value: 'value',
  },
  {
    label: 'Przetworzone',
    value: 'processed',
    formatter: (value: boolean) => (value ? 'Tak' : 'Nie'),
  },
];

const USER_FIELDS = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Utworzony',
    value: 'createdAt',
    formatter: datePrettier,
  },
  {
    label: 'Id w Magento',
    value: 'magentoUserId',
  },
];

const SUB_API_USER_FIELDS = [
  ...USER_FIELDS,
  {
    label: 'Numer karty',
    value: 'cardNumber',
  },
];

export {
  BASIC_FIELDS,
  TRANSACTION_EVENT_FIELDS,
  USER_FIELDS,
  SUB_API_USER_FIELDS,
};
