import { Text, VStack } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import { DEFAULT_ROUTE } from 'app/routes';
import { useAuth } from 'context/AuthContext';
import LoginForm from 'pages/Login/LoginForm';
import StoreSelect from 'components/StoreManager/Select';

function Login() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={DEFAULT_ROUTE} replace />;
  }

  return (
    <VStack spacing={8}>
      <Text variant="h1" w="100%">
        Zaloguj się
      </Text>
      <StoreSelect />
      <LoginForm />
    </VStack>
  );
}

export default Login;
