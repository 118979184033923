import { IconProps } from '@chakra-ui/react';
import {
  Poland,
  Lithuania,
  Lativia,
  Slovakia,
  Germany,
  Romania,
  Portugal,
  Estonia,
  Ukraine,
  Spain,
  CzechRepublic,
  EuropeanUnion,
  Global,
} from 'components/Icons/Flags';

export const STORES = [
  'default',
  'four_fstore_ro',
  'four_fstore_lv',
  'four_fstore_lt',
  'four_fstore_sk',
  'global',
];

export const LABEL_DICT: {
  [K: string]: string;
} = {
  default: 'Polska',
  four_fstore_com: 'Unia Europejska',
  four_fstore_lt: 'Litwa',
  four_fstore_lv: 'Łotwa',
  four_fstore_sk: 'Słowacja',
  four_fstore_de: 'Niemcy',
  four_fstore_cz: 'Czechy',
  four_fstore_ro: 'Rumunia',
  four_fstore_pt: 'Portugalia',
  four_fstore_et: 'Estonia',
  four_fstore_ua: 'Ukraina',
  four_fstore_es: 'Hiszpania',
  global: 'Globalna',
};

export const ICON_DICT: {
  [K: string]: (props: IconProps) => JSX.Element;
} = {
  default: Poland,
  four_fstore_com: EuropeanUnion,
  four_fstore_lt: Lithuania,
  four_fstore_lv: Lativia,
  four_fstore_sk: Slovakia,
  four_fstore_de: Germany,
  four_fstore_cz: CzechRepublic,
  four_fstore_ro: Romania,
  four_fstore_pt: Portugal,
  four_fstore_et: Estonia,
  four_fstore_ua: Ukraine,
  four_fstore_es: Spain,
  global: Global,
};
