import { Icon, IconProps } from '@chakra-ui/react';

function LinearCode(props: IconProps) {
  return (
    <Icon
      width="280px"
      height="64px"
      viewBox="0 0 280 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.86207 0H0V64H3.86207V0Z" fill="black" />
      <path d="M7.72425 0H5.79321V64H7.72425V0Z" fill="black" />
      <path d="M17.3793 0H11.5862V64H17.3793V0Z" fill="black" />
      <path d="M23.1725 0H21.2415V64H23.1725V0Z" fill="black" />
      <path d="M28.9656 0H25.1035V64H28.9656V0Z" fill="black" />
      <path d="M38.6207 0H32.8276V64H38.6207V0Z" fill="black" />
      <path d="M44.4137 0H42.4827V64H44.4137V0Z" fill="black" />
      <path d="M52.1378 0H50.2068V64H52.1378V0Z" fill="black" />
      <path d="M57.9309 0H54.0688V64H57.9309V0Z" fill="black" />
      <path d="M69.5172 0H63.7241V64H69.5172V0Z" fill="black" />
      <path d="M77.2413 0H75.3103V64H77.2413V0Z" fill="black" />
      <path d="M83.0344 0H79.1724V64H83.0344V0Z" fill="black" />
      <path d="M88.8276 0H84.9656V64H88.8276V0Z" fill="black" />
      <path d="M98.4828 0H96.5518V64H98.4828V0Z" fill="black" />
      <path d="M102.345 0H100.414V64H102.345V0Z" fill="black" />
      <path d="M110.069 0H106.207V64H110.069V0Z" fill="black" />
      <path d="M119.724 0H112V64H119.724V0Z" fill="black" />
      <path d="M125.517 0H121.655V64H125.517V0Z" fill="black" />
      <path d="M129.379 0H127.448V64H129.379V0Z" fill="black" />
      <path d="M135.172 0H131.31V64H135.172V0Z" fill="black" />
      <path d="M144.828 0H139.034V64H144.828V0Z" fill="black" />
      <path d="M154.483 0H148.69V64H154.483V0Z" fill="black" />
      <path d="M160.276 0H156.414V64H160.276V0Z" fill="black" />
      <path d="M168 0H162.207V64H168V0Z" fill="black" />
      <path d="M171.862 0H169.931V64H171.862V0Z" fill="black" />
      <path d="M179.586 0H177.655V64H179.586V0Z" fill="black" />
      <path d="M185.379 0H181.517V64H185.379V0Z" fill="black" />
      <path d="M193.103 0H191.172V64H193.103V0Z" fill="black" />
      <path d="M202.759 0H198.896V64H202.759V0Z" fill="black" />
      <path d="M210.483 0H204.69V64H210.483V0Z" fill="black" />
      <path d="M214.345 0H212.414V64H214.345V0Z" fill="black" />
      <path d="M220.138 0H216.276V64H220.138V0Z" fill="black" />
      <path d="M231.724 0H225.931V64H231.724V0Z" fill="black" />
      <path d="M241.379 0H233.655V64H241.379V0Z" fill="black" />
      <path d="M249.103 0H243.31V64H249.103V0Z" fill="black" />
      <path d="M252.965 0H251.034V64H252.965V0Z" fill="black" />
      <path d="M258.759 0H254.896V64H258.759V0Z" fill="black" />
      <path d="M270.345 0H264.552V64H270.345V0Z" fill="black" />
      <path d="M274.207 0H272.276V64H274.207V0Z" fill="black" />
      <path d="M280 0H276.138V64H280V0Z" fill="black" />
    </Icon>
  );
}

export default LinearCode;
