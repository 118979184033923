import { Box, Button, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import StoreChangeModal from 'components/StoreManager/Modal';
import Logout from 'components/Icons/Logout';
import { useAuth } from 'context/AuthContext';
import { ICON_DICT, LABEL_DICT } from 'components/StoreManager/dictionary';

function Footer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logout, store: currentStore } = useAuth();

  return (
    <Stack w="100%" align="start" spacing={0}>
      <Box w="100% " padding="24px 16px">
        <Button
          variant="unstyled"
          leftIcon={<Icon as={ICON_DICT[currentStore]} fontSize="24px" />}
          display="flex"
          textTransform="none"
          fontWeight={400}
          fontSize="16px"
          height="auto"
          onClick={onOpen}
        >
          {LABEL_DICT[currentStore]}
        </Button>
      </Box>
      <Box w="100%" padding="24px 16px">
        <Button
          variant="unstyled"
          leftIcon={<Logout />}
          display="flex"
          textTransform="none"
          fontWeight={400}
          fontSize="16px"
          height="auto"
          onClick={logout}
        >
          Wyloguj się
        </Button>
      </Box>
      <StoreChangeModal
        onClose={onClose}
        isOpen={isOpen}
        currentStore={currentStore}
      />
    </Stack>
  );
}

export default Footer;
