import { get } from 'lodash';
import { BASIC_FIELDS } from 'pages/SubscriptionCoupons/Preview/constants';
import DataRow from 'pages/SubscriptionCoupons/Preview/components/DataRow';

interface BasicDetailsSectionProps {
  subscriptionCoupon?: SubscriptionCoupon;
}

function BasicDetailsSection({ subscriptionCoupon }: BasicDetailsSectionProps) {
  return (
    <>
      {BASIC_FIELDS.map((field) => {
        const value = get(subscriptionCoupon, field.value);
        return <DataRow key={field.value} field={field} value={value} />;
      })}
    </>
  );
}

BasicDetailsSection.defaultProps = {
  subscriptionCoupon: null,
};

export default BasicDetailsSection;
