import { Box, Stack } from '@chakra-ui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import Switch from 'components/Form/Switch';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Input name="code" label="Kod" placeholder="Wpisz kod" />
        <Input
          name="value"
          label="Liczba dni"
          placeholder="Wpisz liczbę dni"
          type="number"
        />
        <Input
          name="internalLabel"
          label="Etykieta wewnętrzna"
          placeholder="Wpisz etykietę"
        />
        <Switch
          name="shouldAwardWelcomeCoupon"
          label="Wydawanie kuponu powitalnego"
        />
      </Stack>
    </Box>
  );
}

export default FormBody;
