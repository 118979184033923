import {
  FormControl,
  CheckboxProps as ChakraCheckboxProps,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import get from 'lodash/get';

interface RadioBoxControlProps extends ChakraCheckboxProps {
  name: string;
  label?: string | React.ReactNode;
  group?: boolean;
  isClearable?: boolean;
  isReadOnly?: boolean;
  onClickCallback?: () => void;
}

export const Label = styled(FormLabel)`
  position: relative;
  cursor: pointer;

  > input {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -9999px;

    &:checked {
      & + span {
        background-color: #0b24fb14;
        color: #0b24fb;
        border-color: transparent;
      }
    }
  }

  > span {
    display: block;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
`;

function RadioBoxControl({
  id,
  label,
  name,
  isRequired,
  defaultChecked,
  value,
  group,
  isClearable,
  isReadOnly,
  isDisabled,
  onClickCallback,
}: RadioBoxControlProps) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      isDisabled={isDisabled}
      w="auto"
    >
      <Label htmlFor={id || name} opacity={isReadOnly ? 0.5 : 1}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultChecked}
          render={({ field: { onChange, value: formValue } }) => (
            <>
              <input
                id={id || name}
                type="radio"
                name={name}
                value={value || ''}
                checked={value === formValue}
                onClick={() => {
                  if (isClearable && value === formValue) setValue(name, null);
                  onClickCallback?.();
                }}
                onChange={(e) => {
                  if (!isReadOnly) {
                    onChange(e);
                  }
                }}
              />
              <Text
                as="span"
                padding="4px 12px"
                fontSize="14px"
                display="block"
                border="1px solid"
                borderRadius="8px"
                color="complementary.placeholder"
                lineHeight="20px"
                borderColor={
                  errorMessage
                    ? 'complementary.error'
                    : 'complementary.placeholder'
                }
              >
                {label}
              </Text>
            </>
          )}
        />
      </Label>
      {!group && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

RadioBoxControl.defaultProps = {
  label: '',
  group: false,
  isClearable: false,
  isReadOnly: false,
  onClickCallback: null,
};

export default RadioBoxControl;
