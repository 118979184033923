import { Box, Button, Collapse, Divider, Stack, Text } from '@chakra-ui/react';
import { get } from 'lodash';
import { useState } from 'react';
import { TRANSACTION_EVENT_FIELDS } from 'pages/SubscriptionCoupons/Preview/constants';
import DataRow from 'pages/SubscriptionCoupons/Preview/components/DataRow';

interface TransactionEventsSectionProps {
  transactionEvents?: TransactionEvent[];
}

function TransactionEventsSection({
  transactionEvents,
}: TransactionEventsSectionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Text fontWeight={700}>Wydarzenia:</Text>

      <Stack spacing={2} divider={<Divider />} pl={8}>
        {transactionEvents?.map((event) => (
          <Stack spacing={2} key={event?._id}>
            {TRANSACTION_EVENT_FIELDS.map((field) => {
              const value = get(event, field.value);

              return <DataRow key={field.value} field={field} value={value} />;
            })}

            <Box>
              <Button
                variant="link"
                padding={0}
                height="auto"
                onClick={() => setIsOpen((wasOpen) => !wasOpen)}
              >
                {`${isOpen ? 'Ukryj' : 'Pokaż'} dodatkowe informacje`}
              </Button>
              <Collapse in={isOpen}>
                <Box
                  as="pre"
                  p={4}
                  fontSize="14px"
                  backgroundColor="complementary.superLightPurple"
                  borderRadius="4px"
                  overflow="auto"
                  mt={2}
                >
                  {JSON.stringify(event?.additionalData, null, 2)}
                </Box>
              </Collapse>
            </Box>
          </Stack>
        ))}
      </Stack>
    </>
  );
}

TransactionEventsSection.defaultProps = {
  transactionEvents: [],
};

export default TransactionEventsSection;
