import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Button,
  Stack,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';
import FormBody from 'pages/Coupons/Form/Create/components/FormBody';
import { DEFAULT_VALUES } from 'pages/Coupons/Form/Create/constants';
import AccordionButton from 'components/AccordionButton';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'coupons',
    keyName: 'fieldId',
  });

  return (
    <Accordion allowMultiple defaultIndex={[0]} w="100%">
      <Stack w="100%" spacing={4}>
        {fields.map((field, fieldIndex) => (
          <Card as={Stack} spacing={4} key={field.fieldId}>
            {isLoading && <LoadingSpinner />}
            <AccordionItem>
              <AccordionButton
                title={`${fieldIndex + 1} kupon rabatowy`}
                remove={() => remove(fieldIndex)}
              />
              <AccordionPanel>
                <FormBody index={fieldIndex} />
              </AccordionPanel>
            </AccordionItem>
          </Card>
        ))}
        <Card>
          <Button
            variant="outlined"
            w="100%"
            onClick={() => append(DEFAULT_VALUES.coupons[0])}
          >
            Dodaj
          </Button>
        </Card>
      </Stack>
    </Accordion>
  );
}

export default ContainerWrapper;
