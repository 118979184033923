import { Stack, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<SubscriptionCoupon, string>[] {
  return [
    {
      header: 'Kod',
      accessorKey: 'code',
      cell: (info) => info.getValue() ?? '-',
    },
    {
      header: 'Opis',
      accessorKey: 'description',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Wartość',
      accessorKey: 'value',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Pula',
      accessorKey: 'couponPool.label',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Ważny od',
      accessorKey: 'validFrom',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'Wykorzystany',
      accessorKey: 'usedAt',
      cell: (info) => (info.getValue() ? datePrettier(info.getValue()) : '-'),
    },
    {
      header: 'Użytkownik',
      accessorKey: 'subApiUser.email',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Zamówienie',
      cell: ({
        row: {
          original: {
            externalTransactionsTotal,
            eShopOrderNumber,
            salesDocumentNumber,
          },
        },
      }) => (
        <Stack>
          <Text>Wartość: {externalTransactionsTotal || '-'}</Text>
          <Text>Numer zamówienia: {eShopOrderNumber || '-'}</Text>
          <Text>Numer dokumentu: {salesDocumentNumber || '-'}</Text>
        </Stack>
      ),
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
