import { Box } from '@chakra-ui/react';

function PreviewLayout({ children }: { children: React.ReactNode }) {
  return (
    <Box
      backgroundColor="complementary.superLightPurple"
      minH="100vh"
      w="100%"
      padding="52px 32px"
      overflow="auto"
    >
      {children}
    </Box>
  );
}

export default PreviewLayout;
