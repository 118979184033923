import { Box, Flex, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileWithPath } from 'react-dropzone';
import ContainerWrapper from 'pages/CouponPools/Form/components/ContainerWrapper';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  FormData,
} from 'pages/CouponPools/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import notify from 'utils/notify';
import { useGetCouponPool } from 'api/couponPools';
import HeaderNavigation from 'components/HeaderNavigation';
import ROUTES from 'app/routes';
import FormFooter from 'components/FormFooter';
import { FOOTER_HEIGHT } from 'components/Footer';
import parseDefaultValues from 'pages/CouponPools/Form/Edit/parseDefaultValues';
import { getUploadData } from 'utils/file';
import CouponPreview from 'pages/CouponPools/Form/components/Preview';

function CouponPoolEdit() {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const backPath = ROUTES.couponPools.base;

  const {
    data: { data: couponPool } = {},
    isFetching,
    isSuccess,
  } = useGetCouponPool(id);

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const { mutate: editCouponPool, isLoading: isEditing } = useMutation({
    mutationKey: 'couponPoolsMutation',
    mutationFn: (data: FormData) =>
      API.patch(APIRoutes.couponPools.byId(id), data),
    onSuccess: () => {
      navigate(backPath);
      notify('success', 'Zmiany w puli kuponów zostały zastosowane');
      queryClient.invalidateQueries('couponPools');
      queryClient.invalidateQueries('couponPoolsOptions');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas edycji puli kuponów');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => {
    const bgImage = data?.bgImage as FileWithPath;
    const bgImageData = bgImage?.path ? await getUploadData(bgImage) : null;

    const modalImage = data?.modalImage as FileWithPath;
    const modalImageData = modalImage?.path
      ? await getUploadData(modalImage)
      : null;

    editCouponPool({
      ...data,
      bgImageData,
      modalImageData,
    });
  });

  useEffect(() => {
    if (isSuccess && couponPool) {
      reset({ ...DEFAULT_VALUES, ...parseDefaultValues(couponPool) });
    }
  }, [couponPool, isSuccess, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Pule kuponów',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj' }]}
      />
      <FormProvider {...methods}>
        <VStack
          w="100%"
          h="100%"
          as="form"
          id="coupon-pool-editor"
          onSubmit={onSubmit}
          justify="space-between"
          mb={FOOTER_HEIGHT}
        >
          <Flex gap={4} mb={4} alignItems="flex-start" w="100%">
            <ContainerWrapper
              isLoading={isLoading}
              isSystemPool={couponPool?.isSystemPool}
              isEditMode
            />
            <CouponPreview />
          </Flex>
          <FormFooter
            onCancel={() => navigate(backPath)}
            isLoading={isLoading}
            isDisabled={!isValid}
            formId="coupon-pool-editor"
          />
        </VStack>
      </FormProvider>
    </Box>
  );
}

export default CouponPoolEdit;
