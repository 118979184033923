import {
  Box,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SketchPicker, ColorChangeHandler, Color } from 'react-color';
import ColorPicker from 'components/Icons/ColorPicker';

interface InputProps {
  onChange: (...event: any[]) => void;
  value: string;
}

const PRESET_COLORS = [
  { color: 'transparent', title: 'Usuń tło' },
  '#000000',
  '#FFFFFF',
  '#F9FAF9',
  '#0B24FB',
  '#00FB6E',
  '#F60D45',
];

function Input({ value, onChange }: InputProps) {
  let debounce: ReturnType<typeof setTimeout>;
  const [color, setColor] = useState<Color>();

  useEffect(() => {
    if (value && !color) {
      setColor(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange: ColorChangeHandler = (newColor) => {
    setColor(newColor.hex);
  };

  const handleChangeComplete: ColorChangeHandler = (newColor) => {
    clearTimeout(debounce);
    debounce = setTimeout(
      () => onChange(newColor.hex === 'transparent' ? null : newColor.hex),
      300,
    );
  };

  return (
    <Box w="100%">
      <Popover>
        <PopoverTrigger>
          <InputGroup>
            <ChakraInput value={value || ''} readOnly />
            <InputRightElement cursor="pointer" w="72px">
              <ColorPicker />
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="min-content">
            <SketchPicker
              color={color}
              presetColors={PRESET_COLORS}
              onChange={handleChange}
              onChangeComplete={handleChangeComplete}
            />
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
}

export default Input;
