import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchExposedEndpoints = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.exposedEndpoints.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.totalCount,
  };
};

const fetchExposedEndpoint = async (id: string) => {
  const { data } = await API.get(APIRoutes.exposedEndpoints.byId(id));
  return data;
};

const searchExposedEndpoint = async (token: string, queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.exposedEndpoints.search(token, queryString),
  );
  return {
    data: response?.data,
    totals: response?.totalCount,
    meta: response?.meta,
  };
};

const useGetExposedEndpoints = (queryString: string) =>
  useQuery<ExposedEndpointsResponse>(
    ['exposedEndpoints', queryString],
    () => fetchExposedEndpoints(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetExposedEndpoint = (id: string) =>
  useQuery<ExposedEndpointResponse>(['exposedEndpoint', id], () =>
    fetchExposedEndpoint(id),
  );

const useSearchExposedEndpoint = (token: string, queryString: string) =>
  useQuery<SearchExposedEndpointRespone>(
    ['searchExposedEndpoint', token, queryString],
    () => searchExposedEndpoint(token, queryString),
  );

export {
  useGetExposedEndpoints,
  useGetExposedEndpoint,
  useSearchExposedEndpoint,
};
