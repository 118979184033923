import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import FormBody from 'pages/ActivationCodes/Form/components/FormBody';
import {
  FormData,
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ActivationCodes/Form/constants';
import { API, APIRoutes } from 'api';
import FormButtons from 'components/FormButtons';
import { useFormWithSchema } from 'utils/formHooks';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { useGetActivationCode } from 'api/activationCodes';

function ActivationCodeEdit({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const { data: { data: activationCode } = {}, isFetching } =
    useGetActivationCode(id);

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const { mutate: editActivationCode, isLoading: isEditing } = useMutation({
    mutationKey: 'activationCodesMutation',
    mutationFn: (data: FormData) =>
      API.patch(APIRoutes.activationCodes.byId(id), data),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Zmiany w kodzie aktywacyjnym zostały zastosowane');
      queryClient.invalidateQueries('activationCodes');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas edycji kodu aktywacyjnego');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) =>
    editActivationCode(data),
  );

  useEffect(() => {
    if (activationCode) reset({ ...DEFAULT_VALUES, ...activationCode });
  }, [activationCode, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="activation-code-editor"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} isEditMode />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="activation-code-editor"
        />
      </DrawerFooter>
    </>
  );
}

export default ActivationCodeEdit;
