import {
  Flex,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Box,
  Icon,
} from '@chakra-ui/react';
import useQueryParams from 'utils/useQueryParams';
import { SIDEBAR_WIDTH } from 'components/Layout/constants';
import ChevronRight from 'components/Icons/ChevronRight';
import ChevronsRight from 'components/Icons/ChevronsRight';

export const FOOTER_HEIGHT = '56px';

interface PaginantionFooterProps {
  totals: number;
  footerButtons?: React.ReactNode;
  hidePagination?: boolean;
  fullWidth?: boolean;
}
function PaginationFooter({
  totals,
  hidePagination = false,
  footerButtons,
  fullWidth,
}: PaginantionFooterProps) {
  const { search, setSearch } = useQueryParams();

  const page = Number(search.get('page'));
  const pageSize = Number(search.get('limit'));

  const lastPage = Math.ceil(totals / +pageSize);

  const onPageSizeChange = (ps: string) => {
    search.set('page', '1');
    search.set('limit', ps);
    setSearch(search);
  };

  const handleClick = (name: string, value: string) => {
    search.set(name, value);
    setSearch(search);
  };

  return (
    <Box
      position="fixed"
      bottom={0}
      left={fullWidth ? 0 : SIDEBAR_WIDTH}
      zIndex={10}
      height={FOOTER_HEIGHT}
      width={fullWidth ? '100%' : `calc(100vw - ${SIDEBAR_WIDTH}px);`}
      display="flex"
      alignItems="center"
      backgroundColor="complementary.white"
      borderTop="solid 1px #eef2fb"
    >
      <Flex paddingInline="48px" w="100%">
        {footerButtons}
        <Box w="100%" />
        <HStack spacing={4} hidden={hidePagination}>
          <Flex align="center">
            <Text mr={2}>{pageSize}</Text>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="unstyled"
                aria-label="Page size"
                icon={
                  <Icon
                    as={ChevronRight}
                    transform="rotate(90deg)"
                    fontSize={24}
                  />
                }
              />
              <MenuList minW="80px">
                <MenuItem
                  isDisabled={+pageSize === 10}
                  onClick={() => onPageSizeChange('10')}
                >
                  10
                </MenuItem>
                <MenuItem
                  isDisabled={+pageSize === 25}
                  onClick={() => onPageSizeChange('25')}
                >
                  25
                </MenuItem>
                <MenuItem
                  isDisabled={+pageSize === 50}
                  onClick={() => onPageSizeChange('50')}
                >
                  50
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <Text whiteSpace="nowrap">
            {totals === 0 && '0 - 0 z 0'}
            {totals !== 0 &&
              `${(+page - 1) * +pageSize || 1} - ${
                +page * +pageSize > totals ? totals : +page * +pageSize
              } z ${totals}`}
          </Text>
          <Flex alignItems="center">
            <IconButton
              aria-label="First page"
              variant="unstyled"
              icon={
                <Icon
                  as={ChevronsRight}
                  transform="rotate(180deg)"
                  fontSize={24}
                />
              }
              onClick={() => handleClick('page', '1')}
            />
            <IconButton
              aria-label="Prev page"
              variant="unstyled"
              icon={
                <Icon
                  as={ChevronRight}
                  transform="rotate(180deg)"
                  fontSize={24}
                />
              }
              onClick={() => {
                if (page > 1) handleClick('page', (+page - 1).toString());
              }}
            />
            <IconButton
              aria-label="Next page"
              variant="unstyled"
              onClick={() => {
                if (+page + 1 <= lastPage)
                  handleClick('page', (+page + 1).toString());
              }}
              icon={<Icon as={ChevronRight} fontSize={24} />}
            />
            <IconButton
              aria-label="Last page"
              variant="unstyled"
              onClick={() => handleClick('page', lastPage.toString())}
              icon={<Icon as={ChevronsRight} fontSize={24} />}
            />
          </Flex>
        </HStack>
      </Flex>
    </Box>
  );
}

PaginationFooter.defaultProps = {
  hidePagination: false,
  footerButtons: [],
  fullWidth: false,
};
export default PaginationFooter;
