import { FormProvider, useForm } from 'react-hook-form';
import useQueryParams from 'utils/useQueryParams';
import {
  LABEL,
  PAGE,
  TARGET_CHANNEL,
} from 'pages/CouponPools/Listing/constants';
import FilterBox from 'components/FilterBox';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { COUPON_POOL_TARGET_CHANNEL_OPTIONS } from 'pages/Coupons/Form/Create/constants';

function FilterBody() {
  const { search, setSearch } = useQueryParams();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      [LABEL]: search.get(LABEL),
      [TARGET_CHANNEL]: search.get(TARGET_CHANNEL),
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { label, targetChannel } = data;

    if (label) search.set(LABEL, label);
    else search.delete(LABEL);

    if (targetChannel) search.set(TARGET_CHANNEL, targetChannel);
    else search.delete(TARGET_CHANNEL);

    search.set(PAGE, '1');
    setSearch(search);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} id="filter-form">
        <FilterBox
          filterForm={
            <>
              <Input name={LABEL} placeholder="Nazwa" />
              <Select
                name={TARGET_CHANNEL}
                placeholder="Kanał docelowy"
                options={COUPON_POOL_TARGET_CHANNEL_OPTIONS}
                isClearable
              />
            </>
          }
        />
      </form>
    </FormProvider>
  );
}

export default FilterBody;
