import Filter from 'components/Icons/Filter';
import Import from 'components/Icons/Import';
import { useFormDrawer } from 'context/FormDrawerContext';
import ActivationCodeCreate from 'pages/ActivationCodes/Form/Create';
import ActivationCodesGenerate from 'pages/ActivationCodes/Generate';
import Add from 'components/Icons/Add';
import FiltersDrawer from 'pages/ActivationCodes/Listing/components/FiltersDrawer';
import useQueryParams from 'utils/useQueryParams';
import { useDownloadCsv } from 'api/activationCodes';

function useHeaderButtons() {
  const { setFormDrawer } = useFormDrawer();
  const { search } = useQueryParams();
  const internalLabel = search.get('internalLabel') || '';

  const { mutate: downloadCsv, isLoading } = useDownloadCsv(internalLabel);

  return [
    {
      label: 'Kod',
      leftIcon: <Add />,
      onClick: () =>
        setFormDrawer({
          title: 'Nowy kod aktywacyjny',
          content: <ActivationCodeCreate />,
        }),
    },
    {
      label: 'Generuj',
      variant: 'outlined',
      onClick: () =>
        setFormDrawer({
          title: 'Generuj kody aktywacyjne',
          content: <ActivationCodesGenerate />,
        }),
    },
    {
      label: 'Filtruj',
      leftIcon: <Filter />,
      variant: 'link',
      onClick: () =>
        setFormDrawer({
          title: 'Filtruj',
          content: <FiltersDrawer />,
        }),
    },
    {
      label: 'CSV',
      leftIcon: <Import />,
      variant: 'link',
      isLoading,
      isDisabled: !internalLabel,
      tooltip: !internalLabel
        ? 'Wybierz etykietę wewnętrzną, aby wyeksportować CSV'
        : '',
      onClick: () => downloadCsv(),
    },
  ];
}

export default useHeaderButtons;
