const ROUTES = {
  auth: {
    login: '/login',
  },
  coupons: {
    base: '/coupons',
    create: '/coupons/create',
    statistics: '/coupons-statistics',
  },
  users: '/users',
  qrCodes: {
    generator: '/qr-codes-generator',
  },
  couponPools: {
    base: '/coupon-pools',
    create: '/coupon-pools/create',
    edit: '/coupon-pools/:id/edit',
  },
  subscriptionCoupons: '/subscription-coupons',
  activationCodes: '/activation-codes',
  exposedEndpoints: {
    base: '/shared',
    preview: '/shared/:id/preview',
  },
};

export const DEFAULT_ROUTE = ROUTES.coupons.base;

export default ROUTES;
