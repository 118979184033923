import { InferType } from 'yup';
import { Box, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/Coupons/Form/Create/constants';
import { useFormWithSchema } from 'utils/formHooks';
import notify from 'utils/notify';
import { API, APIRoutes } from 'api';
import ContainerWrapper from 'pages/Coupons/Form/Create/components/ContainerWrapper';
import LoadingSpinner from 'components/LoadingSpinner';
import HeaderNavigation from 'components/HeaderNavigation';
import ROUTES from 'app/routes';
import FormFooter from 'components/FormFooter';
import { FOOTER_HEIGHT } from 'components/Footer';

type FormData = InferType<typeof BODY_SCHEMA>;

function CouponsCreate() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const backPath = ROUTES.coupons.base;

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { mutate: createCoupons, isLoading } = useMutation({
    mutationKey: 'couponsMutation',
    mutationFn: (data: FormData) =>
      API.post(APIRoutes.coupons.batch, { data: data.coupons }),
    onSuccess: () => {
      navigate(backPath);
      notify('success', 'Kupony zostały pomyślnie utworzone!');
      queryClient.invalidateQueries('coupons');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas tworzenia kuponów');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => createCoupons(data));

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Kupony rabatowe',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj' }]}
      />
      <FormProvider {...methods}>
        <VStack
          w="100%"
          h="100%"
          as="form"
          id="coupons-creator"
          onSubmit={onSubmit}
          justify="space-between"
          mb={FOOTER_HEIGHT}
        >
          {isLoading && <LoadingSpinner />}
          <ContainerWrapper isLoading={isLoading} />
          <FormFooter
            onCancel={() => navigate(backPath)}
            isLoading={isLoading}
            isDisabled={!isValid}
            formId="coupons-creator"
          />
        </VStack>
      </FormProvider>
    </Box>
  );
}

export default CouponsCreate;
