const LIMITS = {
  minPasswordLength: 8,
  couponPool: {
    minDelayBetweenCouponAssignments: 0,
  },
  subscriptionCoupon: {
    minValue: 5,
    maxValue: 100,
    minSubscriptionLevel: 0,
  },
  activationCode: {
    minNumberOfCoupons: 1,
    minValue: 1,
    minCodeLength: 10,
  },
};

const ERROR_MESSAGES = {
  required: 'Pole jest wymagane',
  invalidEmail: 'Niewłaściwy adres e-mail',
  minPasswordLength: `Hasło musi zawierać przynajmniej ${LIMITS.minPasswordLength} znaków`,
  invalidNumberType: 'Wprowadź liczbę',
  subscriptionCoupon: {
    minValue: `Minimalna wartość kuponu wynosi ${LIMITS.subscriptionCoupon.minValue}`,
    maxValue: `Maksymalna wartość kuponu wynosi ${LIMITS.subscriptionCoupon.maxValue}`,
    invalidValue: 'Wartość kuponu musi być wielokrotnością liczby 5',
    minSubscriptionLevel: `Minimalna wartość poziomu subskrypcji wynosi ${LIMITS.subscriptionCoupon.minSubscriptionLevel}`,
  },
  activationCode: {
    minNumberOfCoupons: `Minimalna liczba kuponów wynosi ${LIMITS.activationCode.minNumberOfCoupons}`,
    minValue: `Minimalna liczba dni wynosi ${LIMITS.activationCode.minValue}`,
    minCodeLength: `Minimalna długość kodu wynosi ${LIMITS.activationCode.minCodeLength}`,
  },
  couponPool: {
    minDelayBetweenCouponAssignments: `Minimalny czas pomiędzy przydzielaniem kuponów wynosi ${LIMITS.couponPool.minDelayBetweenCouponAssignments} sekund`,
  },
};

export { LIMITS, ERROR_MESSAGES };
