import { useEffect, useState } from 'react';

type Media = {
  url: string | null;
};

type MediaApiObject = {
  big: Media;
  medium: Media;
  small: Media;
  thumb: Media;
  url: string | null;
};

const useImage = (object: Blob | MediaApiObject | any) => {
  const [image, setImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    let img;
    if (object) {
      if (typeof object.type === 'string') {
        img = URL.createObjectURL(object);
      }
      if (object.fileUrl) {
        img = object.fileUrl;
      }
    }

    setImage(img);

    return () => {
      setImage(undefined);
    };
  }, [object]);

  return image;
};

export default useImage;
