import { useIsMutating } from 'react-query';
import MainLayout from 'components/Layout/MainLayout';
import Table from 'pages/SubscriptionCoupons/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetSubscriptionCoupons } from 'api/subscriptionCoupons';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import FiltersDrawer from 'pages/SubscriptionCoupons/Listing/components/FiltersDrawer';
import Add from 'components/Icons/Add';
import { useFormDrawer } from 'context/FormDrawerContext';
import SubscriptionCouponCreate from 'pages/SubscriptionCoupons/Form/Create';
import Filter from 'components/Icons/Filter';
import { SOURCE_SYSTEM } from 'pages/SubscriptionCoupons/Listing/constants';

function SubscriptionCoupons() {
  const { setFormDrawer } = useFormDrawer();
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
    sourceSystem: SOURCE_SYSTEM.eship4F,
  });

  const isMutating = useIsMutating({
    mutationKey: ['subscriptionCouponsMutation'],
  });

  const {
    isFetching,
    data: { data: users, totals } = { data: [], totals: 0 },
  } = useGetSubscriptionCoupons(queryString);
  const showLoading = isMutating || isFetching;

  return (
    <MainLayout
      title="Kupony subskrypcji"
      headerButtons={[
        {
          label: 'Kupon',
          leftIcon: <Add />,
          onClick: () =>
            setFormDrawer({
              title: 'Nowy kupon subskrypcji',
              content: <SubscriptionCouponCreate />,
            }),
        },
        {
          label: 'Filtruj',
          leftIcon: <Filter />,
          variant: 'link',
          onClick: () =>
            setFormDrawer({
              title: 'Filtruj',
              content: <FiltersDrawer />,
            }),
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={users} />}
      <Footer totals={totals} />
    </MainLayout>
  );
}

export default SubscriptionCoupons;
