import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Stack,
} from '@chakra-ui/react';
import { find, kebabCase } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';
import AccordionButton from 'components/AccordionButton';
import { FormBodyProps } from 'types/common';
import FormBody from 'pages/CouponPools/Form/components/FormBody';
import Select from 'components/Form/Select';
import { useGetCouponPoolsTemplates } from 'api/couponPools';
import { FORMS } from 'pages/CouponPools/Form/components/Form/constants';
import { DEFAULT_TEMPLATE_VALUES } from 'pages/CouponPools/Form/constants';

interface ContainerWrapperProps extends FormBodyProps {
  isSystemPool?: boolean;
}

function ContainerWrapper({
  isLoading,
  isSystemPool = false,
}: ContainerWrapperProps) {
  const { control, setValue } = useFormContext();
  const [label, displayTemplate, templateType] = useWatch({
    control,
    name: ['label', 'displayTemplate', 'templateType'],
  });

  const { isFetching, data: { data: templates } = { data: [] } } =
    useGetCouponPoolsTemplates();

  const templatesOptions = useMemo(
    () =>
      templates?.map((template) => ({
        value: template?._id,
        label: template?.name,
      })),
    [templates],
  );

  useEffect(() => {
    setValue('qrCode', kebabCase(label));
  }, [label, setValue]);

  useEffect(() => {
    setValue('templateType', find(templates, { _id: displayTemplate })?.label, {
      shouldTouch: true,
    });
  }, [displayTemplate, setValue, templates]);

  const resetFields = useCallback(
    (_id: string) => {
      const newTemplate = find(templates, {
        _id,
      })?.label;

      if (newTemplate) {
        const defaultValues = DEFAULT_TEMPLATE_VALUES[newTemplate];

        Object.keys(defaultValues).forEach((key) => {
          setValue(key, defaultValues[key]);
        });
      }
    },
    [setValue, templates],
  );

  const Form = FORMS[templateType];

  return (
    <Accordion allowMultiple defaultIndex={[0, 1]} w="100%">
      <Stack w="100%" spacing={4}>
        <FormBody isLoading={isLoading} isSystemPool={isSystemPool} />
        <Card>
          {isLoading && <LoadingSpinner />}
          <AccordionItem>
            <AccordionButton title="Wygląd kuponów dla tej puli" />
            <AccordionPanel>
              <Stack spacing={4}>
                <Select
                  name="displayTemplate"
                  label="Typ kuponu"
                  isLoading={isFetching}
                  options={templatesOptions}
                  onChangeCallback={resetFields}
                />
                {displayTemplate && Form && <Form />}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Card>
      </Stack>
    </Accordion>
  );
}

ContainerWrapper.defaultProps = {
  isSystemPool: false,
};

export default ContainerWrapper;
