import { Icon, IconProps } from '@chakra-ui/react';

function ActivationCode(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_607_1261"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_607_1261)">
        <path
          d="M5.69238 14.8461V10.3461H4.19238V9.15381H6.88466V14.8461H5.69238ZM9.13468 14.8461V12.4423C9.13468 12.1916 9.21946 11.9815 9.38901 11.812C9.55856 11.6424 9.76865 11.5577 10.0193 11.5577H12.1539V10.3461H9.13468V9.15381H12.4616C12.7122 9.15381 12.9223 9.23858 13.0919 9.40813C13.2614 9.57768 13.3462 9.78778 13.3462 10.0384V11.5577C13.3462 11.8083 13.2614 12.0184 13.0919 12.1879C12.9223 12.3575 12.7122 12.4423 12.4616 12.4423H10.327V13.6538H13.3462V14.8461H9.13468ZM15.0962 14.8461V13.6538H18.1155V12.4423H16.0962V11.5577H18.1155V10.3461H15.0962V9.15381H18.4231C18.6738 9.15381 18.8839 9.23858 19.0534 9.40813C19.223 9.57768 19.3077 9.78778 19.3077 10.0384V13.9615C19.3077 14.2121 19.223 14.4222 19.0534 14.5918C18.8839 14.7613 18.6738 14.8461 18.4231 14.8461H15.0962Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default ActivationCode;
