import { Image, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { API, APIRoutes } from 'api';
import { useAuth } from 'context/AuthContext';

type QrCodePreviewProps = {
  qrCodeValue: string;
  isPoolCode?: boolean;
};

function QrCodePreview({ qrCodeValue, isPoolCode }: QrCodePreviewProps) {
  const { store } = useAuth();
  const [imagePath, setImagePath] = useState<string | null>(null);
  const APIRoute = isPoolCode
    ? APIRoutes.qrCodes.pools
    : APIRoutes.qrCodes.shops;

  const qrCodeSrc = useMemo(
    () => `${process.env.REACT_APP_API_URL}${APIRoute(qrCodeValue)}`,
    [qrCodeValue, APIRoute],
  );

  useEffect(() => {
    API.get(qrCodeSrc, {
      responseType: 'blob',
      headers: {
        store,
      },
    }).then((response) => {
      const reader = new window.FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = () => {
        const { result } = reader;
        if (result) setImagePath(result?.toString());
      };
    });
  }, [qrCodeSrc, store]);

  if (!imagePath) {
    return null;
  }

  return (
    <Image
      src={imagePath}
      alt="qr-code-preview"
      fallbackStrategy="onError"
      fallback={
        <Text variant="h2">Wystąpił błąd podczas generowania kodu QR</Text>
      }
    />
  );
}

QrCodePreview.defaultProps = {
  isPoolCode: false,
};

export default QrCodePreview;
