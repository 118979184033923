import { useMemo } from 'react';
import { map } from 'lodash';
import { useGetInternalLabels } from 'api/activationCodes';
import Select from 'components/Form/Select';

type InternalLabelSelectProps = {
  name: string;
};

function InternalLabelSelect({ name }: InternalLabelSelectProps) {
  const { isFetching, data: { data: labels = [] } = {} } =
    useGetInternalLabels();

  const options = useMemo(
    () =>
      map(labels, (label) => ({
        label,
        value: label,
      })),
    [labels],
  );

  return (
    <Select
      name={name}
      label="Etykieta wewnętrzna"
      placeholder="Wybierz etykietę"
      options={options}
      isLoading={isFetching}
      isClearable
    />
  );
}

export default InternalLabelSelect;
