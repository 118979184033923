import { Box, HStack, Stack, Text, Flex, Center } from '@chakra-ui/react';
import Close from 'components/Icons/Close';
import Copy from 'components/Icons/Copy';
import useImage from 'utils/useImage';
import { PreviewProps } from 'types/common';
import LinearCode from 'components/Icons/LinearCode';

function CouponWithImageModalPreview({ formValues }: PreviewProps) {
  const imagePreview = useImage(formValues?.modalImage);

  return (
    <Stack
      bgColor="complementary.white"
      color="complementary.black"
      padding="32px 24px"
      spacing={4}
    >
      <Flex justify="end">
        <Close cursor="pointer" />
      </Flex>
      <Center
        flexShrink={0}
        w="100%"
        h="104px"
        bgColor="complementary.superLightPurple"
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
      >
        {!imagePreview && (
          <Text fontSize="14px" color="complementary.black">
            Twoje zdjęcie
          </Text>
        )}
      </Center>
      <Text variant="h2" w="100%" align="center">
        -10 PLN
      </Text>
      <Text
        fontSize="14px"
        lineHeight="24px"
        maxHeight="108px"
        overflowY="auto"
      >
        Aby dodać nazwę kuponu oraz jego opis wejdź w zakładkę “Kupony rabatowe”
        -&gt; “Zarządzanie” -&gt; Dodaj+ <strong>kupon rabatowy</strong>,
        wypełnij formularz i przypisz tę pulę.
      </Text>
      <Box>
        <Text fontSize="12px" lineHeight="20px" mb={2}>
          Kod wygasa: Wprowadź datę
        </Text>
        <LinearCode w="100%" />
      </Box>
      <HStack spacing={2} justifyContent="space-between" py={2}>
        <Text fontSize="14px" lineHeight="24px">
          012675647309
        </Text>
        <Copy cursor="pointer" />
      </HStack>
    </Stack>
  );
}

export default CouponWithImageModalPreview;
