import * as yup from 'yup';

const DATE = 'date';
const COUPON_POOL_ID = 'couponPoolId';

const schema = yup.object({
  date: yup.date().nullable().required(),
  couponPoolId: yup.string().required(),
});

export { DATE, COUPON_POOL_ID, schema };
