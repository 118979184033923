import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import FormBody from 'pages/SubscriptionCoupons/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/SubscriptionCoupons/Form/constants';
import { API, APIRoutes } from 'api';
import FormButtons from 'components/FormButtons';
import { useFormWithSchema } from 'utils/formHooks';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { useGetSubscriptionCoupon } from 'api/subscriptionCoupons';
import parseDefaultValues from 'pages/SubscriptionCoupons/Form/Edit/parseDefaultValues';

interface SubscriptionCouponEditProps {
  id: string;
}

function SubscriptionCouponEdit({ id }: SubscriptionCouponEditProps) {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const { data: { data: subscriptionCoupon } = {}, isFetching } =
    useGetSubscriptionCoupon(id);

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const { mutate: editSubscriptionCoupon, isLoading: isEditing } = useMutation({
    mutationKey: 'subscriptionCouponsMutation',
    mutationFn: (data: Partial<SubscriptionCoupon>) =>
      API.patch(APIRoutes.subscriptionCoupons.byId(id), {
        ...data,
        user: data?.user?.value || null,
      }),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Zmiany w kuponie subskrypcji zostały zastosowane');
      queryClient.invalidateQueries('subscriptionCoupons');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas edycji kuponu subskrypcji');
    },
  });

  const onSubmit = handleSubmit(async (data: Partial<SubscriptionCoupon>) =>
    editSubscriptionCoupon(data),
  );

  useEffect(() => {
    if (subscriptionCoupon) {
      reset({ ...DEFAULT_VALUES, ...parseDefaultValues(subscriptionCoupon) });
    }
  }, [subscriptionCoupon, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="subscription-coupon-editor"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} isEditMode />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="subscription-coupon-editor"
        />
      </DrawerFooter>
    </>
  );
}

export default SubscriptionCouponEdit;
