import { Box, Text } from '@chakra-ui/react';

interface PreviewPlaceholderProps {
  text: string;
}

function PreviewPlaceholder({ text }: PreviewPlaceholderProps) {
  return (
    <Box bgColor="complementary.white" padding={2}>
      <Box bgColor="complementary.lightBlue" padding="38px 8px">
        <Text fontSize="14px" lineHeight="20px" width="100%" align="center">
          {text}
        </Text>
      </Box>
    </Box>
  );
}

export default PreviewPlaceholder;
