import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DrawerBody,
  DrawerFooter,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import useQueryParams from 'utils/useQueryParams';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import Input from 'components/Form/Input';
import CheckboxChip from 'components/Form/CheckboxChip';
import Select from 'components/Form/Select';
import { SOURCE_SYSTEM_OPTIONS } from 'pages/SubscriptionCoupons/Listing/constants';

function FiltersDrawer() {
  const { closeFormDrawer } = useFormDrawer();
  const { search, setSearch } = useQueryParams();

  const defaultValues = useMemo(() => {
    const params = Object.fromEntries(new URLSearchParams(search));

    const { eShopOrderNumber, salesDocumentNumber, archived } = params;

    return {
      ...params,
      eShopOrderNumber: eShopOrderNumber
        ? decodeURIComponent(eShopOrderNumber)
        : '',
      salesDocumentNumber: salesDocumentNumber
        ? decodeURIComponent(salesDocumentNumber)
        : '',
      archived: !!archived,
    };
  }, [search]);

  const methods = useForm({
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        const newValue =
          value instanceof Date ? value.toISOString() : value.toString();
        search.set(key, newValue);
      } else {
        search.delete(key);
      }
    });

    search.set('page', '1');
    setSearch(search);
    closeFormDrawer();
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="filters-drawer"
            onSubmit={onSubmit}
            spacing={4}
          >
            <Select
              name="sourceSystem"
              label="Źródło"
              placeholder="Wybierz źródło"
              options={SOURCE_SYSTEM_OPTIONS}
              isClearable
            />
            <Input name="code" label="Kod" placeholder="Wpisz kod" />
            <Input name="email" label="Email" placeholder="Wpisz adres email" />
            <Input
              name="eShopOrderNumber"
              label="Numer zamówienia"
              placeholder="Wpisz numer"
            />
            <Input
              name="salesDocumentNumber"
              label="Numer dokumentu sprzedaży"
              placeholder="Wpisz numer"
            />
            <Input name="value" label="Wartość" placeholder="Wpisz wartość" />
            <Text width="100%" fontWeight={600}>
              Zaznacz warunek/ki
            </Text>
            <HStack width="100%" spacing={2}>
              <CheckboxChip name="archived" label="Tylko wykorzystane" />
            </HStack>
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={false}
          isDisabled={false}
          formId="filters-drawer"
        />
      </DrawerFooter>
    </>
  );
}

export default FiltersDrawer;
