import {
  FormControl,
  CheckboxProps as ChakraCheckboxProps,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from './RadioBoxControl';

type CheckboxProps = ChakraCheckboxProps & {
  name: string;
  label?: string | React.ReactNode;
  onChangeCallback?: (checked: boolean) => void;
};

function CheckboxChip({
  label,
  name,
  isRequired,
  defaultChecked,
  onChangeCallback,
}: CheckboxProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      width="fit-content"
    >
      <Label htmlFor={name}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultChecked}
          render={({ field: { onChange, value } }) => (
            <>
              <input
                id={name}
                type="checkbox"
                checked={!!value}
                onChange={(event) => {
                  const isChecked = event.target.checked;

                  onChange(isChecked);
                  onChangeCallback?.(isChecked);
                }}
              />
              <Text
                as="span"
                padding="4px 12px"
                fontSize="14px"
                display="block"
                border="1px solid"
                borderRadius="8px"
                color="complementary.placeholder"
                lineHeight="20px"
                borderColor={
                  errorMessage
                    ? 'complementary.error'
                    : 'complementary.placeholder'
                }
              >
                {label}
              </Text>
            </>
          )}
        />
      </Label>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

CheckboxChip.defaultProps = {
  label: '',
  onChangeCallback: () => {},
};

export default CheckboxChip;
