import * as yup from 'yup';
import { startOfDay } from 'date-fns';
import { ERROR_MESSAGES, LIMITS } from 'utils/form';

const DEFAULT_VALUES = {
  code: '',
  user: undefined,
  value: 5,
  validFrom: startOfDay(new Date()),
  subscriptionLevel: 0,
};

const BODY_SCHEMA = yup.object({
  code: yup.string().required(ERROR_MESSAGES.required),
  user: yup.mixed().nullable().required(ERROR_MESSAGES.required),
  value: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required)
    .min(
      LIMITS.subscriptionCoupon.minValue,
      ERROR_MESSAGES.subscriptionCoupon.minValue,
    )
    .max(
      LIMITS.subscriptionCoupon.maxValue,
      ERROR_MESSAGES.subscriptionCoupon.maxValue,
    )
    .test(
      'isValidValue',
      ERROR_MESSAGES.subscriptionCoupon.invalidValue,
      (value) => value % 5 === 0,
    ),
  validFrom: yup.date().nullable(),
  subscriptionLevel: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required)
    .min(
      LIMITS.subscriptionCoupon.minSubscriptionLevel,
      ERROR_MESSAGES.subscriptionCoupon.minSubscriptionLevel,
    ),
});

const transformToOption = (user: SubscriptionUser) => ({
  label: `${user?.email}${user?.cardNumber ? ` (${user.cardNumber})` : ''}`,
  value: user?._id,
});

export { DEFAULT_VALUES, BODY_SCHEMA, transformToOption };
