import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DrawerBody,
  DrawerFooter,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import useQueryParams from 'utils/useQueryParams';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import Input from 'components/Form/Input';
import DatePicker from 'components/Form/Date';
import CheckboxChip from 'components/Form/CheckboxChip';
import InternalLabelSelect from 'components/InternalLabelSelect';

function FiltersDrawer() {
  const { closeFormDrawer } = useFormDrawer();
  const { search, setSearch } = useQueryParams();

  const defaultValues = useMemo(() => {
    const params = Object.fromEntries(new URLSearchParams(search));

    const { usedAfter, usedBefore, onlyAvailable, onlyUsed, onlyUnused } =
      params;

    return {
      ...params,
      usedAfter: usedAfter ? new Date(usedAfter) : null,
      usedBefore: usedBefore ? new Date(usedBefore) : null,
      onlyAvailable: !!onlyAvailable,
      onlyUsed: !!onlyUsed,
      onlyUnused: !!onlyUnused,
    };
  }, [search]);

  const methods = useForm({
    mode: 'onChange',
  });

  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = handleSubmit(async (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        const newValue =
          value instanceof Date ? value.toISOString() : value.toString();
        search.set(key, newValue);
      } else {
        search.delete(key);
      }
    });

    search.set('page', '1');
    setSearch(search);
    closeFormDrawer();
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="filters-drawer"
            onSubmit={onSubmit}
            spacing={4}
          >
            <Input name="id" label="Id" placeholder="Wpisz identyfikator" />
            <Input
              name="emailLike"
              label="Email"
              placeholder="Wpisz adres email"
            />
            <Input name="value" label="Wartość" placeholder="Wpisz wartość" />
            <Input name="code" label="Kod" placeholder="Wpisz kod" />
            <InternalLabelSelect name="internalLabel" />
            <DatePicker
              name="usedAfter"
              label="Wykorzystany po"
              showError
              showTimeInput
            />
            <DatePicker
              name="usedBefore"
              label="Wykorzystany przed"
              showError
              showTimeInput
            />
            <Text width="100%" fontWeight={600}>
              Zaznacz warunek/ki
            </Text>
            <HStack width="100%" spacing={2}>
              <CheckboxChip name="onlyAvailable" label="Tylko dostępne" />
              <CheckboxChip
                name="onlyUsed"
                label="Tylko wykorzystane"
                onChangeCallback={(checked) => {
                  if (checked) {
                    setValue('onlyUnused', false);
                  }
                }}
              />
              <CheckboxChip
                name="onlyUnused"
                label="Tylko niewykorzystane"
                onChangeCallback={(checked) => {
                  if (checked) {
                    setValue('onlyUsed', false);
                  }
                }}
              />
            </HStack>
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={false}
          isDisabled={false}
          formId="filters-drawer"
        />
      </DrawerFooter>
    </>
  );
}

export default FiltersDrawer;
