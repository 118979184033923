import { ColumnDef } from '@tanstack/react-table';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<ActivationCode, string>[] {
  return [
    {
      header: 'Kod',
      accessorKey: 'code',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Liczba dni',
      accessorKey: 'value',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Użytkownik',
      accessorKey: 'user.email',
      cell: (info) => info.getValue() ?? '-',
    },
    {
      header: 'Wykorzystany',
      accessorKey: 'usedAt',
      cell: (info) => (info.getValue() ? datePrettier(info.getValue()) : '-'),
    },
    {
      header: 'Etykieta',
      accessorKey: 'internalLabel',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
