const TOKEN_KEY = 'codemate_token';
const STORE_KEY = 'codemate_store';

const getAuthToken = (store: string) =>
  localStorage.getItem(`${TOKEN_KEY}_${store}`);

const getAuthStore = () => localStorage.getItem(STORE_KEY) || 'default';

const setAuthToken = (token: string, store: string) =>
  localStorage.setItem(`${TOKEN_KEY}_${store}`, token);

const setAuthStore = (store: string) => localStorage.setItem(STORE_KEY, store);

export { getAuthToken, getAuthStore, setAuthToken, setAuthStore };
