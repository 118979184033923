import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import FormBody from 'pages/ActivationCodes/Form/components/FormBody';
import {
  FormData,
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ActivationCodes/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import transformErrors from 'utils/api';

function ActivationCodeCreate() {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  const { mutate: createActivationCode, isLoading } = useMutation({
    mutationKey: 'activationCodesMutation',
    mutationFn: (data: FormData) =>
      API.post(APIRoutes.activationCodes.index(), data),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Kod aktywacyjny został pomyślnie utworzony!');
      queryClient.invalidateQueries('activationCodes');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas tworzenia kodu aktywacyjnego');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) =>
    createActivationCode(data),
  );

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="activation-code-creator"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="activation-code-creator"
        />
      </DrawerFooter>
    </>
  );
}

export default ActivationCodeCreate;
