import { FormProvider } from 'react-hook-form';
import { Button, HStack } from '@chakra-ui/react';
import { useFormWithSchema } from 'utils/formHooks';
import useQueryParams from 'utils/useQueryParams';
import DatePicker from 'components/Form/Date';
import CouponPoolSelect from 'components/CouponPoolSelect';
import Card from 'components/Card';
import {
  COUPON_POOL_ID,
  DATE,
  schema,
} from 'pages/Coupons/Statistics/components/Search/constants';
import { dateFormatNoTime, dateParseNoTime } from 'utils/date';

function Search() {
  const { search, setSearch } = useQueryParams();

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: {
      date: dateParseNoTime(search.get(DATE)),
      couponPoolId: search.get(COUPON_POOL_ID) ?? '',
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = handleSubmit(async ({ date, couponPoolId }) => {
    if (date) {
      search.set(DATE, dateFormatNoTime(date));
    }
    if (couponPoolId) {
      search.set(COUPON_POOL_ID, couponPoolId);
    }
    setSearch(search);
  });

  return (
    <FormProvider {...methods}>
      <Card as="form" onSubmit={onSubmit}>
        <HStack spacing={4}>
          <DatePicker placeholderText="Wybierz datę początkową" name={DATE} />
          <CouponPoolSelect name={COUPON_POOL_ID} />
          <Button type="submit" isDisabled={!isValid} flexShrink={0}>
            Szukaj
          </Button>
        </HStack>
      </Card>
    </FormProvider>
  );
}

export default Search;
