import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const DEFAULT_VALUES = {
  code: '',
  value: 0,
  internalLabel: '',
  shouldAwardWelcomeCoupon: false,
};

const BODY_SCHEMA = yup.object({
  code: yup.string().required(ERROR_MESSAGES.required),
  value: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  internalLabel: yup.string().nullable(),
  shouldAwardWelcomeCoupon: yup.boolean().required(ERROR_MESSAGES.required),
});

type FormData = yup.InferType<typeof BODY_SCHEMA>;

export { DEFAULT_VALUES, BODY_SCHEMA };
export type { FormData };
