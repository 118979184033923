import { Stack, Text } from '@chakra-ui/react';
import { get } from 'lodash';
import {
  SUB_API_USER_FIELDS,
  USER_FIELDS,
} from 'pages/SubscriptionCoupons/Preview/constants';
import DataRow from 'pages/SubscriptionCoupons/Preview/components/DataRow';

interface UserSectionProps {
  user?: User | SubscriptionUser;
  isSubApiUser?: boolean;
}

function UserSection({ user, isSubApiUser }: UserSectionProps) {
  const fields = isSubApiUser ? SUB_API_USER_FIELDS : USER_FIELDS;

  return (
    <>
      <Text fontWeight={700}>
        {isSubApiUser ? 'Użytkownik w API subskrypcyjnym' : 'Użytkownik'}:
      </Text>
      <Stack spacing={2} pl={8}>
        {fields.map((field) => {
          const value = get(user, field.value);
          return <DataRow key={field.value} field={field} value={value} />;
        })}
      </Stack>
    </>
  );
}

UserSection.defaultProps = {
  user: null,
  isSubApiUser: false,
};

export default UserSection;
