/* eslint-disable @typescript-eslint/dot-notation */
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setAuthHeader = (token: string) => {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const setStoreHeader = (store: string) => {
  API.defaults.headers.common['Store'] = store;
};

const destroySession: () => void = () => {
  delete API.defaults.headers.common['Authorization'];
};

const APIRoutes = {
  auth: {
    signIn: '/auth/sign_in',
  },
  coupons: {
    index: (queryString?: string) => `/coupons${queryString ?? ''}`,
    batch: '/coupons/batch',
    pools: '/coupons/pools/list',
    import: '/coupons/batch/csv',
    statistics: ({ couponPoolId, date }: CouponPoolStatisticsParams) =>
      `/coupons/report/${couponPoolId}?detailedFromDate=${date}`,
  },
  users: {
    index: (queryString?: string) => `/users${queryString ?? ''}`,
    byId: (id: string) => `/users/${id}`,
  },
  qrCodes: {
    shops: (value: string) => `/shops/${value}-qr.png`,
    pools: (value: string) => `/qr/${value}.png`,
  },
  couponPools: {
    index: (queryString?: string) => `/coupon_pools${queryString ?? ''}`,
    byId: (id: string) => `/coupon_pools/${id}`,
    templates: '/display_templates',
    uploadImage: '/uploads',
    usageReport: '/coupon_pools/reports/usage',
  },
  subscriptionCoupons: {
    index: (queryString?: string) =>
      `/subscription_coupons${queryString ?? ''}`,
    byId: (id: string) => `/subscription_coupons/${id}`,
    users: (phrase: string) => `/subscription_users?phrase=${phrase}`,
  },
  activationCodes: {
    index: (queryString?: string) => `/activation_codes${queryString ?? ''}`,
    byId: (id: string) => `/activation_codes/${id}`,
    generate: '/activation_codes/generate',
    download: {
      csv: (internalLabel?: string) =>
        `/activation_codes/download/csv?internalLabel=${internalLabel}`,
    },
    internalLabels: '/activation_codes/dictionaries/internal_labels',
  },
  exposedEndpoints: {
    index: (queryString?: string) => `/exposed_endpoints${queryString ?? ''}`,
    byId: (id: string) => `/exposed_endpoints/${id}`,
    search: (token: string, queryString?: string) =>
      `/exposed_endpoints/search/by_token${queryString ?? ''}&token=${token}`,
  },
};

const DEFAULT_STALE_TIME = 10 * 1000;
const EXTENDED_STALE_TIME = 60 * 1000;

export {
  API,
  APIRoutes,
  setAuthHeader,
  setStoreHeader,
  destroySession,
  DEFAULT_STALE_TIME,
  EXTENDED_STALE_TIME,
};
