import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import Close from 'components/Icons/Close';
import Copy from 'components/Icons/Copy';
import LinearCode from 'components/Icons/LinearCode';

function BlankCouponModalPreview() {
  return (
    <Stack
      padding="32px 24px"
      spacing={4}
      bgColor="complementary.white"
      color="complementary.black"
    >
      <HStack spacing={2} justifyContent="space-between">
        <Text fontSize="24px" fontWeight={700}>
          -10 PLN
        </Text>
        <Close cursor="pointer" />
      </HStack>
      <Text
        fontSize="14px"
        lineHeight="24px"
        maxHeight="108px"
        overflowY="auto"
      >
        Aby dodać nazwę kuponu oraz jego opis wejdź w zakładkę “Kupony rabatowe”
        -&gt; “Zarządzanie” -&gt; Dodaj+ <strong>kupon rabatowy</strong>,
        wypełnij formularz i przypisz tę pulę.
      </Text>
      <Box>
        <Text fontSize="12px" lineHeight="20px" mb={2}>
          Kod wygasa: Wprowadź datę
        </Text>
        <LinearCode w="100%" />
      </Box>
      <HStack spacing={2} justifyContent="space-between" py={2}>
        <Text fontSize="14px" lineHeight="24px">
          012675647309
        </Text>
        <Copy cursor="pointer" />
      </HStack>
    </Stack>
  );
}

export default BlankCouponModalPreview;
