import MainLayout from 'components/Layout/MainLayout';
import Table from 'pages/ActivationCodes/Listing/components/Table';
import Footer from 'components/Footer';
import Copy from 'components/Icons/Copy';
import { useDownloadCsv } from 'api/activationCodes';
import Import from 'components/Icons/Import';

function ActivationCodes({ data, totals, queryParams }: PreviewListingProps) {
  const { internalLabel } = queryParams;
  const { mutate: downloadCsv, isLoading } = useDownloadCsv(internalLabel);

  return (
    <MainLayout
      title="Kody aktywacyjne"
      headerButtons={[
        {
          label: 'Kopiuj link',
          leftIcon: <Copy boxSize="24px" />,
          onClick: () =>
            navigator.clipboard.writeText(window.location.toString()),
        },
        {
          label: 'CSV',
          leftIcon: <Import />,
          variant: 'link',
          isLoading,
          onClick: () => downloadCsv(),
        },
      ]}
    >
      <Table data={data} />
      <Footer totals={totals} fullWidth />
    </MainLayout>
  );
}

export default ActivationCodes;
