import { FormControl, FormLabel } from '@chakra-ui/react';
import { useAuth } from 'context/AuthContext';
import SingleSelect from 'components/Form/Select/SingleSelect';
import { LABEL_DICT, STORES } from 'components/StoreManager/dictionary';

const STORE_OPTIONS = STORES.map((store) => ({
  value: store,
  label: LABEL_DICT[store],
}));

function StoreSelect() {
  const { store, changeStore } = useAuth();

  return (
    <FormControl>
      <FormLabel>Wybierz maskę</FormLabel>
      <SingleSelect
        instanceId="mask"
        placeholder="Wybierz kraj, w którym chcesz pracować"
        value={STORE_OPTIONS.find((option) => option.value === store)}
        onChange={(newValue: any) => changeStore(newValue.value)}
        options={STORE_OPTIONS}
      />
    </FormControl>
  );
}

export default StoreSelect;
