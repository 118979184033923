import { ColumnDef } from '@tanstack/react-table';
import { CONTROLLERS_DICT } from 'pages/ExposedEndpoints/Form/constants';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<ExposedEndpoint, string>[] {
  return [
    {
      header: 'Nazwa',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Data utworzenia',
      accessorKey: 'createdAt',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'Typ',
      accessorKey: 'controller',
      cell: (info) => CONTROLLERS_DICT[info.getValue()],
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
