import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICON_DICT, LABEL_DICT } from 'components/StoreManager/dictionary';
import { useAuth } from 'context/AuthContext';
import Check from 'components/Icons/Check';
import ROUTES from 'app/routes';

type StoreButtonProps = {
  store: string;
  closeModal: () => void;
  isChecked: boolean;
};

function StoreButton({ isChecked, store, closeModal }: StoreButtonProps) {
  const navigate = useNavigate();
  const { changeStore } = useAuth();

  const handleClick = useCallback(() => {
    changeStore(store);
    closeModal();
    navigate(ROUTES.auth.login);
  }, [changeStore, closeModal, navigate, store]);

  return (
    <Box
      padding="8px 16px"
      as="button"
      w="240px"
      onClick={handleClick}
      _hover={{ bg: 'complementary.lightBlue' }}
    >
      <HStack h="40px" w="100%" spacing={4}>
        <Icon as={ICON_DICT[store]} fontSize="40px" />
        <Text w="100%" textAlign="left">
          {LABEL_DICT[store]}
        </Text>
        {isChecked && <Check />}
      </HStack>
    </Box>
  );
}

export default StoreButton;
