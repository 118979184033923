enum SOURCE_SYSTEM {
  eship4F = 'eshop4F',
  iXPos = 'iXPos',
}

const SOURCE_SYSTEM_OPTIONS = Object.values(SOURCE_SYSTEM).map((value) => ({
  label: value,
  value,
}));

export { SOURCE_SYSTEM, SOURCE_SYSTEM_OPTIONS };
