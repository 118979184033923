import { useState } from 'react';
import { Accordion } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import ExpandableNavItem from 'components/Navigation/ExpandableItem';
import NavItem from 'components/Navigation/Item';
import ROUTES from 'app/routes';
import QR from 'components/Icons/QR';
import Users from 'components/Icons/Users';
import Discount from 'components/Icons/Discount';
import CouponPools from 'components/Icons/CouponPools';
import { ExpandableNavOption, NavOption } from 'types/navigation';
import useQueryParams from 'utils/useQueryParams';
import ActivationCode from 'components/Icons/ActivationCode';
import Shared from 'components/Icons/Shared';

const NAV_OPTIONS: Array<NavOption | ExpandableNavOption> = [
  {
    title: 'Kupony rabatowe',
    icon: Discount,
    options: [
      { title: 'Zarządzanie', link: ROUTES.coupons.base },
      { title: 'Statystyki', link: ROUTES.coupons.statistics },
    ],
  },
  {
    title: 'Kupony subskrypcji',
    icon: Discount,
    link: ROUTES.subscriptionCoupons,
  },
  {
    title: 'Pule kuponów',
    icon: CouponPools,
    link: ROUTES.couponPools.base,
  },
  {
    title: 'Kody aktywacyjne',
    icon: ActivationCode,
    link: ROUTES.activationCodes,
  },
  {
    title: 'Użytkownicy',
    icon: Users,
    link: ROUTES.users,
  },
  {
    title: 'Generator QR',
    icon: QR,
    link: ROUTES.qrCodes.generator,
  },
  {
    title: 'Udostępnione',
    icon: Shared,
    link: ROUTES.exposedEndpoints.base,
  },
];

function Navigation() {
  const { queryString } = useQueryParams();
  const { pathname } = useLocation();
  const [index, setIndex] = useState<number | number[]>(-1);

  return (
    <Accordion
      allowToggle
      h="100%"
      w="100%"
      overflowX="hidden"
      overflowY="auto"
      index={index}
      onChange={(element) => setIndex(element)}
    >
      {NAV_OPTIONS.map((option) => {
        if ('link' in option) {
          const link = option.link === pathname ? queryString : option.link;

          return (
            <NavItem
              key={`nav_option_${option.title}`}
              link={link}
              title={option.title}
              icon={<option.icon />}
            />
          );
        }
        return (
          <ExpandableNavItem
            key={`nav_option_${option.title}`}
            options={option.options}
            title={option.title}
            icon={<option.icon />}
          />
        );
      })}
    </Accordion>
  );
}

export default Navigation;
