import { useMutation, useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';
import notify from 'utils/notify';

const fetchActivationCodes = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.activationCodes.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.totalCount,
  };
};

const fetchActivationCode = async (id: string) => {
  const { data } = await API.get(APIRoutes.activationCodes.byId(id));
  return data;
};

const fetchInternalLabels = async () => {
  const { data } = await API.get(APIRoutes.activationCodes.internalLabels);
  return data;
};

const useGetActivationCodes = (queryString: string) =>
  useQuery<ActivationCodesResponse>(
    ['activationCodes', queryString],
    () => fetchActivationCodes(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetActivationCode = (id: string) =>
  useQuery<ActivationCodeResponse>(['activationCode', id], () =>
    fetchActivationCode(id),
  );

const useGetInternalLabels = () =>
  useQuery<InternalLabelsResponse>(
    ['internalLabels'],
    () => fetchInternalLabels(),
    {
      staleTime: EXTENDED_STALE_TIME,
    },
  );

const useDownloadCsv = (label: string) =>
  useMutation({
    mutationFn: () => API.get(APIRoutes.activationCodes.download.csv(label)),
    onSuccess: (response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', `${label}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas eksportu kodów aktywacyjnych!');
    },
  });

export {
  useGetActivationCodes,
  useGetActivationCode,
  useGetInternalLabels,
  useDownloadCsv,
};
