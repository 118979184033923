import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';

const fetchSubscriptionCoupons = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.subscriptionCoupons.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.totalCount,
  };
};

const fetchSubscriptionCoupon = async (id: string) => {
  const { data } = await API.get(APIRoutes.subscriptionCoupons.byId(id));
  return data;
};

const fetchSubscriptionUsers = async (phrase: string) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.subscriptionCoupons.users(phrase));

  return data;
};

const useGetSubscriptionCoupons = (queryString: string) =>
  useQuery<SubscriptionCouponsResponse>(
    ['subscriptionCoupons', queryString],
    () => fetchSubscriptionCoupons(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetSubscriptionCoupon = (id: string) =>
  useQuery<SubscriptionCouponResponse>(['subscriptionCoupon', id], () =>
    fetchSubscriptionCoupon(id),
  );

const useGetSubscriptionUsers = (phrase: string) =>
  useQuery<SubscriptionUser[]>(
    ['subscriptionUsers', phrase],
    () => fetchSubscriptionUsers(phrase),
    {
      enabled: !!phrase,
      staleTime: EXTENDED_STALE_TIME,
    },
  );

export {
  useGetSubscriptionCoupons,
  useGetSubscriptionCoupon,
  useGetSubscriptionUsers,
};
