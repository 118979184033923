import { useMemo, useState } from 'react';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import qs from 'query-string';
import { startOfDay, endOfDay } from 'date-fns';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetCouponsUsage } from 'api/analytics';

export interface StatsTooltipProps {
  createdAt: string;
  couponPool: string;
}

function StatsTooltip({ createdAt, couponPool }: StatsTooltipProps) {
  const [previewIsVisible, setPreviewIsVisible] = useState(false);

  const queryString = useMemo(() => {
    const params = {
      timeFrom: startOfDay(new Date(createdAt)).toISOString(),
      timeTo: endOfDay(new Date()).toISOString(),
      couponPool,
    };

    return qs.stringify(params);
  }, [createdAt, couponPool]);

  const { data: stats, isFetching } = useGetCouponsUsage(
    queryString,
    previewIsVisible,
  );

  return (
    <Tooltip
      onOpen={() => setPreviewIsVisible(true)}
      onClose={() => setPreviewIsVisible(false)}
      padding={0}
      openDelay={500}
      label={
        <Box
          boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.25)"
          maxH="fit-content"
          minW="221px"
          maxW="300px"
          bg="white"
          padding={8}
          overflow="hidden"
          textAlign="left"
          cursor="pointer"
          color="initial"
        >
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <Box overflowX="auto" fontSize="16px">
              <Text>
                <Text as="span" fontWeight={600}>
                  Wykorzystane kupony:{' '}
                </Text>
                {stats?.count}
              </Text>
            </Box>
          )}
        </Box>
      }
    >
      <Text cursor="pointer" textDecoration="underline">
        Wyświetl
      </Text>
    </Tooltip>
  );
}

export default StatsTooltip;
