const parseDefaultValues = (couponPool: CouponPool) => {
  const result: Record<string, any> = {
    ...couponPool,
  };

  result.displayTemplate = couponPool?.displayTemplate?._id;

  return result;
};

export default parseDefaultValues;
