import { Box, Stack } from '@chakra-ui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import DatePicker from 'components/Form/Date';
import Autocomplete from 'pages/SubscriptionCoupons/Form/components/Autocomplete';

function FormBody({ isLoading, isEditMode }: FormBodyProps) {
  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Input name="code" label="Kod kuponu" placeholder="Wpisz kod" />
        <Autocomplete />
        <Input
          name="value"
          label="Wartość"
          placeholder="Wpisz wartość"
          type="number"
        />
        <DatePicker name="validFrom" label="Ważny od" showError showTimeInput />
        <Input
          name="subscriptionLevel"
          label="Poziom subskrypcji"
          placeholder="Wpisz poziom"
          type="number"
          isReadOnly={isEditMode}
        />
      </Stack>
    </Box>
  );
}

export default FormBody;
