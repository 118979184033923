/* eslint-disable import/prefer-default-export */
import { FunctionComponent } from 'react';
import { TEMPLATE_TYPE } from 'pages/CouponPools/Form/constants';
import BlankCouponForm from 'pages/CouponPools/Form/components/Form/BlankCouponForm';
import CouponWithImageForm from 'pages/CouponPools/Form/components/Form/CouponWithImageForm';
import B2BCouponForm from 'pages/CouponPools/Form/components/Form/B2BCouponForm';

const FORMS: {
  [key: string]: FunctionComponent;
} = {
  [TEMPLATE_TYPE.BLANK]: BlankCouponForm,
  [TEMPLATE_TYPE.WITH_IMAGE]: CouponWithImageForm,
  [TEMPLATE_TYPE.B2B]: B2BCouponForm,
};

export { FORMS };
