import { Text } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import Input from 'components/Form/Input';

function B2BCouponForm() {
  return (
    <>
      <Text fontSize="16px" fontWeight={600}>
        Link (opcjonalnie)
      </Text>
      <Input
        name="linkName"
        label="Nazwa linku"
        placeholder="Wprowadź nazwę, aby wygenerować podgląd"
      />
      <Input
        name="link"
        label="Adres linku"
        placeholder="Wprowadź adres linku"
      />
      <Text fontSize="16px" fontWeight={600}>
        Zdjęcie
      </Text>
      <DropzoneField
        name="bgImage"
        title="Dodaj zdjęcie w formacie .png, .jpg (min. 92 px x 116 px)"
        acceptImages
      />
      <Text fontSize="16px" fontWeight={600}>
        Zdjęcie w modalu
      </Text>
      <DropzoneField
        name="modalImage"
        title="Dodaj zdjęcie w formacie .png, .jpg (min. 280 px x 104 px)"
        acceptImages
      />
    </>
  );
}

export default B2BCouponForm;
