import { Button, Center, HStack, Stack, Text } from '@chakra-ui/react';
import { PreviewProps } from 'types/common';
import useImage from 'utils/useImage';

function CouponWithImagePreview({ formValues }: PreviewProps) {
  const imagePreview = useImage(formValues?.bgImage);

  return (
    <HStack
      w="100%"
      h="132px"
      bgColor={formValues?.textBgColor ?? 'complementary.white'}
      color={formValues?.textColor ?? 'complementary.black'}
    >
      <Center
        flexShrink={0}
        w="104px"
        h="100%"
        bgColor="complementary.superLightPurple"
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
      >
        {!imagePreview && (
          <Text fontSize="14px" color="complementary.black">
            Twoje zdjęcie
          </Text>
        )}
      </Center>
      <Stack spacing={1} pr={4}>
        <Text fontWeight={600}>-10 PLN</Text>
        <Text fontSize="12px" lineHeight="20px">
          Opis kuponu
        </Text>
        <Text fontSize="12px" lineHeight="20px">
          Kod wygasa: Wprowadź datę
        </Text>
        <Button
          variant="link"
          padding={0}
          fontSize="10px"
          lineHeight="20px"
          fontWeight={700}
          width="fit-content"
          color={formValues?.linkColor ?? 'complementary.primary'}
        >
          Użyj w sklepie
        </Button>
      </Stack>
    </HStack>
  );
}

export default CouponWithImagePreview;
