import { Stack, Text, Flex, Center, Button } from '@chakra-ui/react';
import Close from 'components/Icons/Close';
import useImage from 'utils/useImage';
import { PreviewProps } from 'types/common';

function B2BCouponModalPreview({ formValues }: PreviewProps) {
  const imagePreview = useImage(formValues?.modalImage);

  return (
    <Stack bgColor="complementary.white" padding="32px 24px" spacing={4}>
      <Flex justify="end">
        <Close cursor="pointer" />
      </Flex>
      <Center
        flexShrink={0}
        w="100%"
        h="104px"
        bgColor="complementary.superLightPurple"
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
      >
        {!imagePreview && <Text fontSize="14px">Twoje zdjęcie</Text>}
      </Center>
      <Text variant="h2" w="100%" align="center">
        NAZWA KUPONU
      </Text>
      <Text maxHeight="108px" overflowY="auto">
        Aby dodać nazwę kuponu oraz jego opis wejdź w zakładkę “Kupony rabatowe”
        -&gt; “Zarządzanie” -&gt; Dodaj+ <strong>kupon rabatowy</strong>,
        wypełnij formularz i przypisz tę pulę.
      </Text>
      {formValues?.linkName && (
        <Button
          variant="link"
          px={0}
          fontSize="12px"
          fontWeight={700}
          width="fit-content"
        >
          {formValues?.linkName}
        </Button>
      )}
      <Text fontSize="12px" lineHeight="20px" mb={2}>
        Kod wygasa: Wprowadź datę
      </Text>
    </Stack>
  );
}

export default B2BCouponModalPreview;
