import { HStack, Text } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import ColorPicker from 'components/Form/Color';

function CouponWithImageForm() {
  return (
    <>
      <HStack spacing={2}>
        <ColorPicker label="Kolor tekstu" name="textColor" />
        <ColorPicker label="Kolor tła tekstu" name="textBgColor" />
      </HStack>
      <ColorPicker label="Kolor przycisku" name="linkColor" />
      <Text fontSize="16px" fontWeight={600}>
        Zdjęcie
      </Text>
      <DropzoneField
        name="bgImage"
        title="Dodaj zdjęcie w formacie .png, .jpg (min. 92 px x 116 px)"
        acceptImages
      />
      <Text fontSize="16px" fontWeight={600}>
        Zdjęcie w modalu
      </Text>
      <DropzoneField
        name="modalImage"
        title="Dodaj zdjęcie w formacie .png, .jpg (min. 280 px x 104 px)"
        acceptImages
      />
    </>
  );
}

export default CouponWithImageForm;
