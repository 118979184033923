import { useMutation } from 'react-query';
import Papa from 'papaparse';
import { API, APIRoutes } from 'api';
import notify from 'utils/notify';

const HEADERS = {
  label: 'Nazwa',
  totalCoupons: 'Liczba wszystkich kuponów',
  assignedCoupons: 'Liczba przypisanych kuponów',
  availableCoupons: 'Liczba dostępnych kuponów',
};

function HeaderButtons() {
  const { mutate: getUsageReport, isLoading } = useMutation({
    mutationKey: 'activationCodesMutation',
    mutationFn: () => API.get(APIRoutes.couponPools.usageReport),
    onSuccess: ({ data }) => {
      const header = Papa.unparse({
        fields: Object.values(HEADERS),
        data: [],
      });
      const body = Papa.unparse(data, {
        header: false,
        columns: Object.keys(HEADERS),
      });
      const blob = new Blob([header + body], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'raport.csv';
      a.click();
      URL.revokeObjectURL(url);
      notify('success', 'Raport został wygenerowany!');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas generowania raportu');
    },
  });

  return [
    {
      label: 'Eksportuj',
      isLoading,
      onClick: () => getUsageReport(),
    },
  ];
}

export default HeaderButtons;
