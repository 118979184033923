import { Icon, IconProps } from '@chakra-ui/react';

function ColorPicker(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_414_1304"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_414_1304)">
        <path
          d="M3 20.9999V16.2499L11.95 7.2999L10.5 5.8999L11.95 4.4999L13.85 6.3999L16.95 3.2999C17.0333 3.21657 17.1375 3.1499 17.2625 3.0999C17.3875 3.0499 17.5167 3.0249 17.65 3.0249C17.7833 3.0249 17.9083 3.0499 18.025 3.0999C18.1417 3.1499 18.25 3.21657 18.35 3.2999L20.7 5.6499C20.7833 5.7499 20.85 5.85824 20.9 5.9749C20.95 6.09157 20.975 6.21657 20.975 6.3499C20.975 6.48324 20.95 6.6124 20.9 6.7374C20.85 6.8624 20.7833 6.96657 20.7 7.0499L17.625 10.1249L19.525 12.0749L18.1 13.4999L16.7 12.0499L7.75 20.9999H3ZM5 18.9999H6.95L15.25 10.6499L13.35 8.7499L5 17.0499V18.9999ZM16.175 8.7499L18.575 6.3499L17.65 5.4249L15.25 7.8249L16.175 8.7499Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default ColorPicker;
