import { Text } from '@chakra-ui/react';
import { PreviewField } from 'types/common';

interface DataRowProps {
  field: PreviewField;
  value: any;
}

function DataRow({ field, value }: DataRowProps) {
  return (
    <Text key={field.value} fontWeight={700}>
      {field.label}:{' '}
      <Text as="span">
        {field?.formatter ? field?.formatter(value) : value ?? '-'}
      </Text>
    </Text>
  );
}

export default DataRow;
