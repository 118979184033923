import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import QrCodePreview from 'components/QrCodePreview';

interface QrCodePreviewModalProps {
  label: string;
  qrCodeValue: string;
  isSystemPool: boolean;
}

function QrCodePreviewModal({
  label,
  qrCodeValue,
  isSystemPool,
}: QrCodePreviewModalProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  if (!qrCodeValue || isSystemPool) {
    return <Text>Brak</Text>;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalHeader>
              <Text variant="h4" fontWeight={600}>
                {label}
              </Text>
            </ModalHeader>
            <QrCodePreview qrCodeValue={qrCodeValue} isPoolCode />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Text cursor="pointer" textDecoration="underline" onClick={onOpen}>
        Podgląd
      </Text>
    </>
  );
}

export default QrCodePreviewModal;
