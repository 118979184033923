import { DrawerBody, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useGetSubscriptionCoupon } from 'api/subscriptionCoupons';
import LoadingSpinner from 'components/LoadingSpinner';
import BasicDetailsSection from 'pages/SubscriptionCoupons/Preview/components/BasicDetailsSection';
import TransactionEventsSection from 'pages/SubscriptionCoupons/Preview/components/TransactionEventsSection';
import UserSection from 'pages/SubscriptionCoupons/Preview/components/UserSection';

function SubscriptionCouponPreview({ id }: { id: string }) {
  const { data: { data: subscriptionCoupon } = {}, isFetching } =
    useGetSubscriptionCoupon(id);

  return (
    <DrawerBody>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <Stack spacing={2}>
          <BasicDetailsSection subscriptionCoupon={subscriptionCoupon} />
          {subscriptionCoupon?.user && (
            <UserSection user={subscriptionCoupon.user} />
          )}
          <UserSection user={subscriptionCoupon?.subApiUser} isSubApiUser />
          {!isEmpty(subscriptionCoupon?.transactionEvents) && (
            <TransactionEventsSection
              transactionEvents={subscriptionCoupon?.transactionEvents}
            />
          )}
        </Stack>
      )}
    </DrawerBody>
  );
}

export default SubscriptionCouponPreview;
