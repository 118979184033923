import debounce from 'lodash/debounce';
import { useMemo, useState, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { GroupBase, SelectInstance } from 'react-select';
import map from 'lodash/map';
import { useGetSubscriptionUsers } from 'api/subscriptionCoupons';
import SingleSelect from 'components/Form/Select/SingleSelect';
import { transformToOption } from 'pages/SubscriptionCoupons/Form/constants';

function Autocomplete() {
  const { control } = useFormContext();
  const ref = useRef<SelectInstance<any, boolean, GroupBase<any>> | null>(null);
  const [querySearchValue, setQuerySearchValue] = useState<string>('');

  const { data: users = [], isFetching } =
    useGetSubscriptionUsers(querySearchValue);

  const onDebounce = useMemo(
    () =>
      debounce((value) => {
        setQuerySearchValue(value);
      }, 700),
    [],
  );

  const options = useMemo(() => map(users, transformToOption), [users]);

  return (
    <FormControl>
      <FormLabel htmlFor="user">Użytkownik</FormLabel>
      <Controller
        control={control}
        name="user"
        render={({ field: { onChange, value: fieldValue } }) => {
          return (
            <SingleSelect
              instanceId="search"
              innerRef={ref}
              value={fieldValue}
              placeholder="Szukaj użytkownika"
              isLoading={isFetching}
              loadingMessage={() => 'Ładowanie...'}
              options={options}
              onInputChange={onDebounce}
              onChange={onChange}
              noOptionsMessage={() => null}
              isClearable
            />
          );
        }}
      />
    </FormControl>
  );
}

export default Autocomplete;
