import { Icon, IconProps } from '@chakra-ui/react';

function Shared(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_683_50"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_683_50)">
        <path
          d="M7.75 14.3942V8.7019C7.75 8.20318 7.9266 7.77723 8.2798 7.42403C8.633 7.07083 9.05896 6.89422 9.55768 6.89422H18.1116L15.2865 4.0692L16.3557 3L21 7.6442L16.3557 12.298L15.2865 11.2442L18.1116 8.39418H9.55768C9.46793 8.39418 9.3942 8.42303 9.3365 8.48073C9.2788 8.53843 9.24995 8.61215 9.24995 8.7019V14.3942H7.75ZM5.8077 20.1442C5.30898 20.1442 4.88302 19.9676 4.52982 19.6143C4.17661 19.2611 4 18.8352 4 18.3365V3.95193H5.49997V18.3365C5.49997 18.4262 5.52883 18.5 5.58653 18.5577C5.64423 18.6154 5.71795 18.6442 5.8077 18.6442H17.1922C17.282 18.6442 17.3557 18.6154 17.4134 18.5577C17.4711 18.5 17.5 18.4262 17.5 18.3365V14.3942H19V18.3365C19 18.8352 18.8233 19.2611 18.4701 19.6143C18.1169 19.9676 17.691 20.1442 17.1922 20.1442H5.8077Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Shared;
