import { Box, Flex, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FileWithPath } from 'react-dropzone';
import ContainerWrapper from 'pages/CouponPools/Form/components/ContainerWrapper';
import {
  DEFAULT_VALUES,
  BODY_SCHEMA,
  FormData,
} from 'pages/CouponPools/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import notify from 'utils/notify';
import transformErrors from 'utils/api';
import HeaderNavigation from 'components/HeaderNavigation';
import ROUTES from 'app/routes';
import { FOOTER_HEIGHT } from 'components/Footer';
import LoadingSpinner from 'components/LoadingSpinner';
import FormFooter from 'components/FormFooter';
import { getUploadData } from 'utils/file';
import CouponPreview from 'pages/CouponPools/Form/components/Preview';

function CouponPoolCreate() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const backPath = ROUTES.couponPools.base;

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  const { mutate: createCouponPool, isLoading: isCreatingCouponPool } =
    useMutation({
      mutationKey: 'couponPoolsMutation',
      mutationFn: (data: FormData) =>
        API.post(APIRoutes.couponPools.index(), data),
      onSuccess: () => {
        navigate(backPath);
        notify('success', 'Pula kuponów została pomyślnie utworzona!');
        queryClient.invalidateQueries('couponPools');
        queryClient.invalidateQueries('couponPoolsOptions');
      },
      onError: ({ errors }) => {
        const transformedErrors = transformErrors(errors);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
        notify('error', 'Wystąpił problem podczas tworzenia puli kuponów');
      },
    });

  const onSubmit = handleSubmit(async (data: FormData) => {
    const bgImage = data?.bgImage as FileWithPath;
    const bgImageData = bgImage?.path ? await getUploadData(bgImage) : null;

    const modalImage = data?.modalImage as FileWithPath;
    const modalImageData = modalImage?.path
      ? await getUploadData(modalImage)
      : null;

    createCouponPool({
      ...data,
      bgImageData,
      modalImageData,
    });
  });

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Pule kuponów',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj' }]}
      />
      <FormProvider {...methods}>
        <VStack
          w="100%"
          h="100%"
          as="form"
          id="coupon-pool-creator"
          onSubmit={onSubmit}
          justify="space-between"
          mb={FOOTER_HEIGHT}
        >
          {isCreatingCouponPool && <LoadingSpinner />}
          <Flex gap={4} mb={4} alignItems="flex-start" w="100%">
            <ContainerWrapper isLoading={isCreatingCouponPool} />
            <CouponPreview />
          </Flex>

          <FormFooter
            onCancel={() => navigate(backPath)}
            isLoading={isCreatingCouponPool}
            isDisabled={!isValid}
            formId="coupon-pool-creator"
          />
        </VStack>
      </FormProvider>
    </Box>
  );
}

export default CouponPoolCreate;
