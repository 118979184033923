import * as yup from 'yup';
import { ERROR_MESSAGES, LIMITS } from 'utils/form';

const DEFAULT_VALUES = {
  internalLabel: '',
  prefix: '',
  suffix: '',
  value: 30,
  allowedCharacters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  numberOfCoupons: 1,
  codeLength: 15,
  shouldAwardWelcomeCoupon: false,
};

const BODY_SCHEMA = yup.object({
  internalLabel: yup.string().required(ERROR_MESSAGES.required),
  prefix: yup.string().nullable(),
  suffix: yup.string().nullable(),
  value: yup
    .number()
    .min(LIMITS.activationCode.minValue, ERROR_MESSAGES.activationCode.minValue)
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  allowedCharacters: yup.string().required(ERROR_MESSAGES.required),
  numberOfCoupons: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required)
    .min(
      LIMITS.activationCode.minNumberOfCoupons,
      ERROR_MESSAGES.activationCode.minNumberOfCoupons,
    ),
  codeLength: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required)
    .min(
      LIMITS.activationCode.minCodeLength,
      ERROR_MESSAGES.activationCode.minCodeLength,
    ),
  shouldAwardWelcomeCoupon: yup.boolean(),
});

type FormData = yup.InferType<typeof BODY_SCHEMA>;

export { DEFAULT_VALUES, BODY_SCHEMA };
export type { FormData };
