import { useIsMutating } from 'react-query';
import MainLayout from 'components/Layout/MainLayout';
import Table from 'pages/ActivationCodes/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetActivationCodes } from 'api/activationCodes';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import useHeaderButtons from 'pages/ActivationCodes/Listing/components/HeaderButtons';

function ActivationCodes() {
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const isMutating = useIsMutating({
    mutationKey: ['activationCodesMutation'],
  });

  const {
    isFetching,
    data: { data: activationCodes, totals } = { data: [], totals: 0 },
  } = useGetActivationCodes(queryString);
  const showLoading = isMutating || isFetching;

  return (
    <MainLayout title="Kody aktywacyjne" headerButtons={useHeaderButtons()}>
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={activationCodes} />}
      <Footer totals={totals} />
    </MainLayout>
  );
}

export default ActivationCodes;
