import { useCallback } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useQueryClient, useMutation } from 'react-query';
import Td from 'pages/ActivationCodes/Listing/components/Table/components/Td';
import Edit from 'components/Icons/Edit';
import Delete from 'components/Icons/Delete';
import Tr from 'components/Table/Tr';
import { API, APIRoutes } from 'api';
import CustomModal from 'components/CustomModal';
import { useFormDrawer } from 'context/FormDrawerContext';
import ActivationCodeEdit from 'pages/ActivationCodes/Form/Edit';
import notify from 'utils/notify';

interface TableRowProps {
  row: Row<ActivationCode>;
}

function TableRow({ row }: TableRowProps) {
  const { _id } = row.original;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();
  const { setFormDrawer } = useFormDrawer();

  const { mutate: removeActivationCode } = useMutation(
    () => API.delete(APIRoutes.activationCodes.byId(_id!)),
    {
      mutationKey: 'activationCodesMutation',
      onSuccess: () => {
        notify('success', 'Pomyślnie usunięto kod aktywacyjny');
        onClose();
        queryClient.invalidateQueries('activationCodes');
      },
      onError: () => {
        notify('error', 'Wystąpił problem podczas usuwania kodu aktywacyjnego');
      },
    },
  );

  const openEditor = useCallback(() => {
    setFormDrawer({
      title: 'Edytuj',
      content: <ActivationCodeEdit id={_id!} />,
    });
  }, [_id, setFormDrawer]);

  return (
    <Tr key={`row_${_id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <CustomModal
            confirmationText="Usuń"
            onAccept={removeActivationCode}
            cancellationText="Anuluj"
            onClose={onClose}
            isOpen={isOpen}
            title="Czy na pewno chcesz trwale usunąć ten kod aktywacyjny?"
          />
          <Tooltip label="Edytuj" openDelay={500}>
            <IconButton
              aria-label="edit"
              icon={<Edit />}
              width="32px"
              height="32px"
              variant="ghost"
              onClick={openEditor}
            />
          </Tooltip>
          <Tooltip label="Usuń" openDelay={500}>
            <IconButton
              aria-label="delete"
              icon={<Delete />}
              width="32px"
              height="32px"
              variant="ghost"
              onClick={onOpen}
            />
          </Tooltip>
        </>
      </Td>
    </Tr>
  );
}

export default TableRow;
