import {
  Text,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import StoreButton from 'components/StoreManager/Modal/StoreButton';
import { STORES } from 'components/StoreManager/dictionary';

interface StoreChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentStore: string;
}

function StoreChangeModal({
  isOpen,
  onClose,
  currentStore,
}: StoreChangeModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalBody>
          <ModalHeader>
            <Text variant="h4" fontWeight={600}>
              Wybierz kraj aplikacji, w której chcesz pracować
            </Text>
          </ModalHeader>
          <Flex
            wrap="wrap"
            maxH="280px"
            direction="column"
            w="100%"
            columnGap={6}
            alignItems="center"
          >
            {STORES.map((store) => (
              <StoreButton
                key={`store_button_${store}`}
                store={store}
                closeModal={onClose}
                isChecked={store === currentStore}
              />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default StoreChangeModal;
