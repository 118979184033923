import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useSearchExposedEndpoint } from 'api/exposedEndpoints';
import useQueryParams from 'utils/useQueryParams';
import LoadingSpinner from 'components/LoadingSpinner';
import { LISTINGS } from 'pages/ExposedEndpoints/Preview/constants';

function ExposedEndpointPreview() {
  const { id = '' } = useParams();
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const {
    isFetching,
    data: { data, totals, meta } = {
      data: [],
      totals: 0,
      meta: { exposedEndpoint: { controller: '', queryParams: {} } },
    },
  } = useSearchExposedEndpoint(id, queryString);

  const controller = useMemo(
    () => meta?.exposedEndpoint?.controller,
    [meta?.exposedEndpoint?.controller],
  );

  const Listing = LISTINGS[controller];

  return isFetching ? (
    <LoadingSpinner />
  ) : (
    <Listing
      data={data}
      totals={totals}
      queryParams={meta?.exposedEndpoint?.queryParams}
    />
  );
}

export default ExposedEndpointPreview;
