/* eslint-disable import/prefer-default-export */
import { FileWithPath } from 'react-dropzone';

const getUploadData = async (file: FileWithPath): Promise<UploadData> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((reslove, reject) => {
    reader.onload = () =>
      reslove({
        uploadData: {
          filename: file?.name,
          base64Content: reader.result?.toString(),
        },
      });
    reader.onerror = (error) => reject(error);
  });
};

export { getUploadData };
